import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    // Datas
    countries: [],
    user: null,
    vouchers: [],

    // Status
    loading: true,
  }),
})