import { basil } from '@spices/basil'
import { CurryModel } from '@spices/curry'
import manifest from './manifest'

export default class Challenge extends CurryModel {
  constructor(data = null) {
    super({ manifest, data })

    if(basil.isNil(basil.get(data, 'completions_count'))) {
      this.completionsCount = null
    }

    if(basil.isNil(basil.get(data, 'global_amount_rewards'))) {
      this.globalAmountRewards = null
    }

    if(basil.isNil(basil.get(data, 'user_amount_rewards'))) {
      this.userAmountRewards = null
    }

    if(basil.isNil(basil.get(data, 'user_completions_count'))) {
      this.userCompletionsCount = null
    }
  }
}
