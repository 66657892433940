import Points from '../points/model'
import RedeemableVoucher from '../redeemable-vouchers/model'
import Rules from '../rules/model'
import Settings from '../settings/model'
import Tier from '../tiers/model'

export default {
  allowDigitalCards: { source: 'allow_digital_cards', type: Boolean }, 
  allowPhysicalCards: { source: 'allow_physical_cards', type: Boolean }, 
  allowPointsRedemption: { source: 'allow_points_redemption', type: Boolean }, 
  allowVouchersRedemption: { source: 'allow_vouchers_redemption', type: Boolean },  
  allowTiers: { source: 'allow_tiers', type: Boolean, },
  badges: { source: 'badges', type: Array }, 
  created: { source: 'created', type: Date }, 
  description: { source: 'description', type: String }, 
  excludedShopIds: { source: 'excluded_shop_ids', type: Array }, 
  id: { source: 'id', type: String }, 
  images: { source: 'images', type: Array }, 
  incentives: { source: 'incentives', type: Array }, 
  modified: { source: 'modified', type: Date }, 
  name: { source: 'name', type: String }, 
  pageSubtitle: { source: 'page_subtitle', type: String }, 
  pageTitle: { source: 'page_title', type: String }, 
  placeholders: { source: 'placeholders', type: Array }, 
  points: { source: 'points', type: Points }, 
  projectId: { source: 'project_id', type: String }, 
  redeemableVouchers: { source: 'redeemable_vouchers', type: Array, format: (values) => {
    return values && values.length > 0 ? values.map(v => new RedeemableVoucher(v)) : []
  }}, 
  rewardEatin: { source: 'reward_eatin', type: Boolean }, 
  rules: { source: 'rules', type: Rules }, 
  settings: { source: 'settings', type: Settings }, 
  slug: { source: 'slug', type: String }, 
  terms: { source: 'terms', type: String },
  tiers: { source: 'tiers', type: Array, format: (values) => {
    return values && values.length ? values.map(v => new Tier(v)) : [] 
  }},

  // Loyalty Page
  pageActive: { source: 'page_active', type: Boolean, default: false },
  pageContent: { source: 'page_content', type: Object },
  pageStyle: { source: 'page_style', type: Array },
}
