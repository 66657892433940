import { basil } from '@spices/basil'

import ChallengeController from './challenges/controller'
import EmbedController from './embeds/controller'
import UserController from './users/controller'
import LocalStorageController from './local-storage/controller'
import LoyaltyController from './loyalty-programs/controller'
import NftController from './nfts/controller'
import PoapController from './poaps/controller'
import NotificationController from './notifications/controller'
import ReferralController from './referrals/controller'
import VideoController from './videos/controller'
import WalletController from './wallets/controller'

import UtilsController from './utils/controller'

import Errors from './error'

/**
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class SaylFrontUserCore {
  /**
   * Create a new instance of SaylFrontUserCore
   *
   * @constructor
   * @param {Object} options
   * @param {Object} options.emitter
   * @param {Object} options.i18n
   * @param {Object} options.logger
   * @param {Object} options.store
   * @param {Object} options.transports Available transports (eg: Axios, pusher, ...)
   */
  constructor({ emitter, i18n, logger, store, transports }) {
    this._logger = logger
    this._locale = null
    this._args = null
    this._emitter = emitter
    this._i18n = i18n

    this._store = store

    this._localStorage = new LocalStorageController({ logger })
    this._utils = new UtilsController({ logger, transports })

    this._challenge = new ChallengeController({ logger, localStorage: this._localStorage, store: this._store, transports })
    this._embed = new EmbedController({ logger, localStorage: this._localStorage, store: this._store, transports })
    this._loyalty = new LoyaltyController({ logger, store: this._store, transports, transports })
    this._nft = new NftController({ logger, store: this._store, transports })
    this._notification = new NotificationController({ logger, store: this._store, transports })
    this._poap = new PoapController({ logger, store: this._store, transports })
    this._referral = new ReferralController({ logger, store: this._store, transports })
    this._user = new UserController({ emitter, i18n, logger, localStorage: this._localStorage, notification: this._notification, store: this._store, transports })
    this._video = new VideoController({ logger, store: this._store, transports })
    this._wallet = new WalletController({ logger, store: this._store, transports })
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {ChallengeController}
   * @readonly
   */
  get challenge() {
    return this._challenge
  }

  /**
   * @property {EmbedController}
   * @readonly
   */
  get embed() {
    return this._embed
  }

  /**
   * @property {LocalStorageController}
   * @readonly
   */
  get localStorage() {
    return this._localStorage;
  }

  /**
   * @property {LoyaltyController}
   * @readonly
   */
  get loyalty() {
    return this._loyalty
  }

  /**
   * @property {NftController} nft
   * @readonly
   */
  get nft() {
    return this._nft
  }

  /**
   * @property {NotificationController}
   * @readonly
   */
  get notification() {
    return this._notification
  }

  /**
   * @property {PoapController}
   * @readonly
   */
  get poap() {
    return this._poap
  }

  /**
   * @property {ReferralController}
   * @readonly
   */
  get referral() {
    return this._referral
  }

  /**
   * @property {UserController}
   * @readonly
   */
  get user() {
    return this._user
  }

  /**
   * @property {UtilsController} utils
   * @readonly
   */
  get utils() {
    return this._utils
  }

  /**
   * @property {VideoController} Video
   * @readonly
   */
  get video() {
    return this._video
  }

  /**
   * @property {WalletController} Wallet
   * @readonly
   */
  get wallet() {
    return this._wallet
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Initialize the core of the project and all the needed controllers
   * 
   * @param {Object} options
   * @param {Object} options.args
   * @param {Object} options.bootstrap
   */
  async init({ args, bootstrap }) {
    try {
      this._logger.group('core.init', bootstrap)

      this._args = args

      this._logger.debug('-args', args)
			this._logger.debug('-bootstrap', bootstrap)

      let loyalty = basil.get(bootstrap, 'loyalty.program', null)
      !basil.isNil(loyalty) ? this._loyalty.init({ program: loyalty }) : null

      let referral = basil.get(bootstrap, 'loyalty.referral.program', null)
      !basil.isNil(referral) ? this._referral.init({ program: referral }) : null

      await this.initLocalStorage({ args: { ...args, project: bootstrap.project_id }})
      await this.initUser()
      await this.initEmbed({ args, embed: bootstrap.embed, shop: bootstrap.shop })
      await this.initNotifications({ args })
      await this.initChallenge()

      if(this.user.user && this.user.user.hasWallet) {
        this.initWallet()
      }
      
      return 
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.init', bootstrap)
    }
  }

  /**
   * Initialize the challenges 
   */
  async initChallenge() {
    try {
      this._logger.group('core.initChallenges')
      await this._challenge.init() 
      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initChallenges')
    }
  }

  /**
   * Init the embed and service
   *
   * @param {Object} options
   * @param {Object} options.embed
   * @param {Object} options.service
   * @param {Object} options.shop
   */
  async initEmbed({ embed }) {
    try { 
      this._logger.group('core.initEmbed')
      
      if(!this._args.embed && embed == null){
        this._logger.warn('No embed defined')
        this._logger.debug(this._args)
        throw Errors.EMBED_NOTACTIVE
      }

      this._logger.debug('- embed', embed)
      await this._embed.init({ args: this._args, embed, locale: this._locale })
      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initEmbed')
    }
  }

  /**
   * Initialize the localStorage handler
   * 
   * @param {Object} options
   * @param {Object} options.args
   */
  async initLocalStorage({ args }) {
    try {
      this._logger.group('core.initLocalStorage')
      await this._localStorage.init({ args })
      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initLocalStorage')
    }
  }

  /**
   * Initialize the notifications controller
   * 
   * @if {@link args.sayl-notification} Will get this only notification
   * @else check if there is notifications pending
   * 
   * @param {Object} args 
   * @returns 
   */
  async initNotifications({ args }) {
    try {
      this._logger.group('core.initNotifications')
      await this.notification.init({ args, user: this.user.user })
      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initNotifications')
    }
  }

  /**
   * Initialize the user (as a guest user)
   */
  async initUser() {
    try {
      this._logger.group('core.initUser')
      
      let lang = null
      if(this._args.lang) {
        lang = this._i18n.locales.find(nl => nl.lang.toString() === this._args.lang)
      }
      lang = !lang ? this._i18n.locale.iso : lang.iso

      await this._user.init({ lang })

      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initUser')
    }
  }

  async initWallet() {
    try {
      this._logger.group('core.initWallet')
      await this._wallet.init({})
      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.initWallet')
    }
  }

  /**
   * Change user lang
   * 
   * @param {Object} options
   * @param {String} options.locale
   */
  async changeLang({ locale }) {
    try {
      this._logger.group('core.changeLang')
      await this._user.changeLang({ locale })
      await this._embed.reset({ locale })
      return
    } catch(e) {
      throw e
    } finally {
      this._logger.groupEnd('core.changeLang')
    }
  }
}
