<template>
  <footer
    class="n-footer"
    v-if="enabled">
    <div class="n-footer__container">
      <!-- Content -->
      <section class="n-footer__content">
        <!-- Powered -->
        <div class="n-footer__powered" v-if="showSaylBranding">
          <a
            class="n-footer__cta n-footer__label"
            :href="$t('user-portal.footer_sayl_link', { lang: lang })"
            target="_blank"
          >{{ $t('user-portal.footer_poweredby') }}</a>
        </div>

        <!-- Visit us -->
        <div 
          class="n-footer__visit" 
          v-if="socials.length > 0">
          <ul class="n-footer__socials">
            <li
              :key="i"
              class="n-footer__social"
              v-for="(s, i) in socials">
              <a
                class="n-footer__sociallink"
                :href="s.href"
                target="_blank"
                :title="s.label">
                <ui-img
                  :alt="s.label"
                  class="n-footer__socialfigure"
                  :src="s.icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </section>

      <!-- License -->
      <section class="n-footer__license">
        <!-- Copyright -->
        <div
          v-if="showSaylBranding"
          class="n-footer__copyright"
          v-html="copyright"
        ></div>
        <div v-else>&nbsp;</div>

        <!-- Legal -->
        <ul class="n-footer__legal">
          <li
            v-for="item in legal"
            :key="item.label"
            class="n-footer__item">
            <a
              class="n-footer__link"
              :href="item.href"
              rel="nofollow"
              target="_blank"
              v-if="item.href"
            >{{ item.label }}</a>

            <p
              class="n-footer__link --action"
              @click="item.click"
              v-if="item.click"
            >{{ item.label }}</p>
          </li>
        </ul>
      </section>
    </div>
  </footer>
</template>

<script>
import SaylStore from '@/bootstrap/stores'
import Config from '@/config'

import UiImg from '@/components/ui/img'

export default {
  name: 'SaylFrontFooter',

  components: {
    UiImg
  },

  inject: [
    '$user',
    '$embed'
  ],

  data() {
    return {
      bootstrap: SaylStore.bootstrap(),
    }
  },

  computed: {
    cookiesDisabled() {
      return this.$basil.get(this.bootstrap, 'cookies.disabled', false)
    },

    copyright() {
      let ret = this.$basil.get(this.$embed, 'embed.copyright', null)
      return ret ? ret : this.$t('user-portal.copyright', { year: this.year, lang: this.lang })
    },

    enabled() {
      let meta = this.$route.meta
      return !meta.hasOwnProperty('footer') || (meta.hasOwnProperty('footer') && meta.footer === true)
    },

    lang() {
      return !this.$basil.isNil(this.user.lang) ? this.user.lang : this.$basil.i18n.locale.lang
    },

    logo() {
      return Config.cdn + 'statics/images/logos/resto/color.svg'
    },

    legal() {
      let terms = this.$basil.get(this.$embed, 'embed.generalConditions', null)
      let privacy = this.$basil.get(this.$embed, 'embed.privacyPolicy', null)

      return [
        { 
          label: this.$t('user-portal.burger_legal_term_and_conditions'), 
          href: terms ? terms : this.$t('user-portal.burger_legal_term_and_conditions_link', { lang: this.lang }) 
        },
        { 
          label: this.$t('user-portal.burger_legal_privacy_policy'), 
          href: privacy ? privacy : this.$t('user-portal.burger_legal_privacy_policy_link', { lang: this.lang }) 
        },
        !this.cookiesDisabled ? {
          label: this.$t('user-portal.burger_legal_cookies_settings'),
          click: () => this.$bus.$emit('cookies-settings')
        } : null
      ].filter(l => l != null)
    },

    showSaylBranding() {
      return !this.$basil.get(this.$embed, 'embed.belongsToPartner', false)
    },

    socials() {
      let ret = this.$basil.get(this.$embed, 'embed.socials', []) || []

      return ret.map((r) => {
        return {
          ...r,
          label: this.$t('user-portal.socials_' + r.type)
        }
      })
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    year() {
      return new Date().getFullYear()
    }
  },
}
</script>
