import LoyaltyTierReward from './rewards/model'

export default {
  active: { source: 'active', type: Boolean, default: false },
  amount: { source: 'amount', type: String },
  created: { source: 'created', type: Date },
  customerId: { source: 'customer_id', type: String },
  description: { source: 'description', type: String },
  id: { source: 'id', type: String },
  modified: { source: 'modified', type: Date },
  name: { source: 'name', type: String },
  options: { source: 'options', type: Object },
  priority: { source: 'priority', type: Number },
  projectId: { source: 'project_id', type: String },
  relatedEntity: { source: 'related_entity', type: String },
  relatedEntityId: { source: 'related_entity_id', type: String },
  rewards: { source: 'rewards', type: Array, format: (values) => {
    return values && values.length > 0 ? values.map(v => new LoyaltyTierReward(v)) : []
  }},
  segmentId: { source: 'segment_id', type: String },
  slug: { source: 'slug', type: String },
  type: { source: 'type', type: String },
}
