import {
  isClaimAuthenticated,
  isNotClaimAuthenticated,

  isPoapClaimAuthenticated,
  isNotPoapClaimAuthenticated,

  isPoapsClaimAuthenticated,
  isNotPoapsClaimAuthenticated
} from '@/helpers/router'

export default () => {
  let ret = [
    ///////////////////
    // NFTs
    {
      name: 'sayl-user-portal.claim.nft',
      path: '/claim/nft/:id',
      component: () => import('./nfts/index.vue'),
      redirect: { name: 'sayl-user-portal.claim.nft.signup' },
      meta: {
        bodyClass: 'layout-claim view-claim-nft',
        scrollTop: true,
        header: false,
        footer: true
      },

      children: [
        {
          name: 'sayl-user-portal.claim.nft.login',
          path: 'login',
          component: () => import('./nfts/login.vue'),
          beforeEnter: isNotClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        },
        {
          name: 'sayl-user-portal.claim.nft.signup',
          path: 'signup',
          component: () => import('./nfts/signup.vue'),
          beforeEnter: isNotClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        },
        {
          name: 'sayl-user-portal.claim.nft.confirm',
          path: 'confirm',
          component: () => import('./nfts/confirm.vue'),
          beforeEnter: isClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        }
      ]
    },

    // Self Claim
    {
      name: 'sayl-user-portal.self-claim.nft',
      path: '/self-claim/nft/:id',
      component: () => import('./nfts/self/index.vue'),
      meta: {
        bodyClass: 'layout-claim view-claim-nft',
        scrollTop: true,
        header: true,
        footer: true,
        selfClaim: true,
      },
    },

    // Peer transfer
    {
      name: 'sayl-user-portal.peer-transfer.nft',
      path: '/transfer/nft/:id',
      component: () => import('./nfts/peer-transfer/index.vue'),
      redirect: { name: 'sayl-user-portal.peer-transfer.nft.signup' },
      meta: {
        bodyClass: 'layout-claim view-claim-nft',
        scrollTop: true,
        header: true,
        footer: true,
        peerTransfer: true,
      },

      children: [
        {
          name: 'sayl-user-portal.peer-transfer.nft.login',
          path: 'login',
          component: () => import('./nfts/login.vue'),
          beforeEnter: isNotClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true,
            peerTransfer: true,
          },
        },
        {
          name: 'sayl-user-portal.peer-transfer.nft.signup',
          path: 'signup',
          component: () => import('./nfts/signup.vue'),
          beforeEnter: isNotClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true,
            peerTransfer: true,
          },
        },
        {
          name: 'sayl-user-portal.peer-transfer.nft.confirm',
          path: 'confirm',
          component: () => import('./nfts/confirm.vue'),
          beforeEnter: isClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true,
            peerTransfer: true,
          },
        }
      ]
    },
    {
      name: 'sayl-user-portal.peer-transfer.sign',
      path: '/sign/nft/:token',
      component: () => import('./sign/index.vue'),
      meta: {
        bodyClass: 'layout-claim view-sign',
        scrollTop: true,
        header: true,
        footer: true,
        peerTransfer: true,
      },
    },


    ///////////////////
    // POAPs
    // 1. Unique
    {
      name: 'sayl-user-portal.claim.poap',
      path: '/claim/poap/:id',
      component: () => import('./poaps/index.vue'),
      redirect: { name: 'sayl-user-portal.claim.poap.signup' },
      meta: {
        bodyClass: 'layout-claim view-claim-poap',
        scrollTop: true,
        header: false,
        footer: true
      },

      children: [
        {
          name: 'sayl-user-portal.claim.poap.login',
          path: 'login',
          component: () => import('./poaps/login.vue'),
          beforeEnter: isNotPoapClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        },
        {
          name: 'sayl-user-portal.claim.poap.signup',
          path: 'signup',
          component: () => import('./poaps/signup.vue'),
          beforeEnter: isNotPoapClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        },
        {
          name: 'sayl-user-portal.claim.poap.confirm',
          path: 'confirm',
          component: () => import('./poaps/confirm.vue'),
          beforeEnter: isPoapClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        }
      ]
    },
    // 2. Multiple
    {
      name: 'sayl-user-portal.claim.poaps',
      path: '/claim/poaps/:token',
      component: () => import('./poaps/collection.vue'),
      redirect: { name: 'sayl-user-portal.claim.poaps.signup' },
      meta: {
        bodyClass: 'layout-claim view-claim-poap',
        scrollTop: true,
        header: false,
        footer: true
      },

      children: [
        {
          name: 'sayl-user-portal.claim.poaps.login',
          path: 'login',
          component: () => import('./poaps/collection/login.vue'),
          beforeEnter: isNotPoapsClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        },
        {
          name: 'sayl-user-portal.claim.poaps.signup',
          path: 'signup',
          component: () => import('./poaps/collection/signup.vue'),
          beforeEnter: isNotPoapsClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        },
        {
          name: 'sayl-user-portal.claim.poaps.confirm',
          path: 'confirm',
          component: () => import('./poaps/collection/confirm.vue'),
          beforeEnter: isPoapsClaimAuthenticated,
          meta: {
            bodyClass: 'layout-claim',
            scrollTop: true,
            header: true,
            footer: true
          },
        }
      ]
    },
  ]

  return ret
}
