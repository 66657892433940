<template>
  <div class="container">
    <component :is="is" />
  </div>
</template>

<script>
import Error from '../../helpers/error'

import BootstrapError from './bootstrap-error'
import EmbedNotFound from './embed-notfound'
import EmbedNotActive from './embed-notactive'
// import ShopNoActiveService from './shop-noactiveservice'
// import ShopNotPublished from './shop-not-published'
// import ServiceNotFound from './service-notfound'
// import ServiceNotActive from './service-notactive'
// import ServiceNoCatalog from './service-nocatalog'
// import ServiceEatinTableNotFound from './service-eatin-table-notfound'
// import ServiceEatinTableInactive from './service-eatin-table-inactive'

export default {
  name: 'ErrorView',

  props: {
    errors: {
      type: Array,
      required: true
    }
  },

  computed: {
    is(){
      let ret = BootstrapError
      let error = this.errors[0];

      switch(error){
        case Error.EMBED_NOTFOUND:
          ret = EmbedNotFound; break;
        // case Error.SHOP_NOT_PUBLISHED:
        //   ret = ShopNotPublished; break;
        // case Error.SHOP_NOACTIVESERVICE:
        //   ret = ShopNoActiveService; break;
        // case Error.SERVICE_NOTFOUND:
        //   ret = ServiceNotFound; break;
        // case Error.SERVICE_NOTACTIVE:
        //   ret = ServiceNotActive; break;
        // case Error.SERVICE_NOCATALOG:
        //   ret = ServiceNoCatalog; break;
        // case Error.SERVICE_EATIN_TABLE_NOTFOUND:
        //   ret = ServiceEatinTableNotFound; break;
        // case Error.SERVICE_EATIN_TABLE_INACTIVE:
        //   ret = ServiceEatinTableInactive; break;
        case Error.EMBED_NOTACTIVE:
          ret = EmbedNotActive; break;
      }

      return ret;
    }
  }
}
</script>
