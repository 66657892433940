export default {
  fqn: 'ifm.wallet',

  endpoints: {
    entity: {
      get: '/user/nft/wallet'
    },

    pendings: {
      get: '/user/nft/claims'
    },

    transfers: {
      get: '/user/nft/transfers'
    },

    vouchers: {
      get: 'user/nft/$address/$serial/vouchers'
    },

    nftData: {
      get: '/user/nft/$address/$serial'
    },

    pin: {
      post: '/user/nft/wallet/reset-pin',
      put: '/user/nft/wallet/pin'
    },
  },
  
  transport: 'cp'
}