export default {
  fqn: 'ifm.local-storage',

  items: {
    user: {
      scopes: ['embed'],
    },

    mode: {
      scopes: [],
    },

    embed: {
      scopes: ['embed'],
    },

    locale: {
      scopes: [],
    },

    lang: {
      scopes: [],
    },

    'firebase-token': {
      scopes: []
    },

    context: {
      scopes: [],
    },

    'app-notifications': {
      scopes: [],
    }
  }
}
