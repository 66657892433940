import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Voucher model
 * 
 * @class
 */
export default class Voucher extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }
}
