<template>
  <div :class="classes">
    <ui-icon v-if="!connected" class="ui-connectivity__icon" :glyph="icon" />
    <div v-if="!connected" class="ui-connectivity__label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'UiConnectivity',

  data(){
    return {
      connected: true
    }
  },

  computed: {
    classes(){
      return {
        'ui-connectivity': true,
        '-offline': !this.connected,
        '-online': this.connected
      }
    },

    icon(){
      return this.connected === true ? 'wifi' : 'disconnected';
    },

    label(){
      return this.$t('user-portal.network_offline');
    }
  },

  methods:{
    update(){
      this.connected = navigator.onLine;
    }
  },

  created(){
    window.addEventListener('online', this.update);
    window.addEventListener('offline', this.update);

    this.update();
  },

  destroyed(){
    window.removeEventListener('online', this.update);
    window.removeEventListener('offline', this.update);
  }
}
</script>
