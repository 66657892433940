import { defineStore } from 'pinia'

export const useWalletStore = defineStore('wallet', {
  state: () => ({
    // Datas
    wallet: null,
    nfts: [],
    nft: null,
    pendings: 0,
    transfers: [],


    // Status
    loading: true,
    loadingTransfers: true,
  }),
})