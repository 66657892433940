export default {
  content: { source: 'content', type: String },
  images: { source: 'images', type: Array },
  subtitle: { source: 'subtitle', type: String },
  title: { source: 'title', type: String },

  // Challenge Page
  pageActive: { source: 'page_active', type: Boolean, default: false },
  pageContent: { source: 'page_content', type: Object },
  pageStyle: { source: 'page_style', type: Array },
}
