import { CurryApi } from '@spices/curry'
import config from './config'

/**
 * Controller class for the entity Nft
 * 
 * @class
 */
export default class NftController {
  /**
   * @constructor
   */
  constructor({ logger, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger
  }

  /////////////////////////////////////////
  ///           METHODS
  async getImageType(url = null) {
    if(url == null) {
      return
    }

    try {
      let transport = this._api._transports.getByName('http')
      let { headers } = await transport.head(url)
      return headers && headers['content-type'] ? headers['content-type'] : null
    } catch(e) {
      this._logger.error(e)
      return null
    }
  }
}
