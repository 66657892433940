export default {
  created: { source: 'created', type: Date },
  id: { source: 'id', type: String },
  generatedEntity: { source: 'generated_entity', type: Object },
  referralId: { source: 'referral_id', type: String },
  type: { source: 'type', type: String },
  

  credit: { source: 'credit', format: (value) => {
    return value == null ? null : value
  }},
  voucher: { source: 'voucher', format: (value) => {
    return value == null ? null : value
  }},
}