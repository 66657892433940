export default {
  from: {
    source: 'from',
    format: value => value !== null ? Date.parse(value) : null
  },
  id: {
    source: 'id',
    type: String
  },
  name: {
    source: 'name',
    type: String
  },
  perk: {
    source: 'perk',
    type: Object
  },
  smartcontractAddress: {
    source: 'smartcontract_address',
    type: String
  },
  to: {
    source: 'to',
    format: value => value !== null ? Date.parse(value) : null
  }
}