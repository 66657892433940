export default {
  // displayType: {
  //   source: 'displayType',
  //   type: String
  // },
  maxValue: {
    source: 'maxValue',
    type: Number,
    default: null,
  },
  name: {
    source: 'name',
    type: String
  },
  // traitCount: {
  //   source: 'traitCount',
  //   type: Number
  // },
  type: {
    source: 'type',
    type: String
  },
  value: {
    source: 'value',
    type: String
  }
}
