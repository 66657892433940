<template>
  <div
    :class="getClasses"
    @click="onClick">
    <div class="ui-notification__header">
      <h5 class="ui-notification__title">{{ value.text }}</h5>

      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="ui-notification__close"
        icon-post="cross"
        :size="$pepper.Size.XS"
        @click.stop.prevent="onRead"
        v-if="user.isLogged"
      />
    </div>

    <div class="ui-notification__meta">
      <i18n-datetime :value="value.created" />
    </div>

    <actions-button
      class="ui-notification__message"
      :appearance="$pepper.Appearance.DEFAULT"
      @click.prevent.stop="onClick"
      v-if="value.message"
    >{{ $t('user-portal.notification_read_message') }}</actions-button>

    <layout-modal
      closable
      :title="value.text"
      :visible="showMessage"
      @close="showMessage = false"
      v-if="value.message && showMessage">
      <div class="ql-editor" v-html="value.message"></div>
    </layout-modal>
  </div>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'UiNotification',

  components: {
    LayoutModal
  },

  inject: ['$user', '$notification'],

  props: {
    selected: {
      type: Boolean,
      default: false
    },

    value: {},
  },

  data() {
    return {
      showMessage: false
    }
  },

  computed: {
    getClasses() {
      return {
        'ui-notification': true,
        '-interactive':
          !(this.$basil.isNil(this.$basil.get(this.value, 'relatedEntity')) ||
          this.$basil.isNil(this.$basil.get(this.value, 'relatedId'))) ||
          !this.$basil.isNil(this.value.message),
        '-selected': this.selected
      }
    },

    user() {
      return this.$user.user
    }
  },

  methods: {
    onClick() {
      if(!this.$basil.isNil(this.value.message)) {
        this.showMessage = true
        return
      }

      if(this.$basil.isNil(this.$basil.get(this.value, 'relatedEntity')) || this.$basil.isNil(this.$basil.get(this.value, 'relatedId'))) {
        return
      }

      let entity = this.$basil.get(this.value, 'relatedEntity', null)

      if(this.$user.user.isLogged) {
        switch(entity) {
          case 'challenge_completion':
            if(this.$route.name != 'sayl-front-user.challenges') {
              this.$router.push({ name: 'sayl-user-portal.challenges', query: { completion: this.$basil.get(this.value, 'relatedId') } }).catch(() => {})
            } else {
              this.$router.replace({ query: { completion: this.$basil.get(this.value, 'relatedId') } }).catch(() => {})
            }
            break;

          case 'referral_reward':
            if(this.$route.name != 'sayl-front-user.loyalty') {
              this.$router.push({ name: 'sayl-user-portal.loyalty', query: { referral: this.$basil.get(this.value, 'relatedId') } }).catch(() => {})
            } else {
              this.$router.replace({ query: { referral: this.$basil.get(this.value, 'relatedId') } }).catch(() => {})
            }
            break;
        }
      } else {
        switch(entity) {
          case 'challenge_completion':
            this.$router.push({ name: 'sayl-user-portal.login', query: { redirect: 'sayl-front-user.challenges', completion: this.$basil.get(this.value, 'relatedId') } }).catch(() => {})
            break;

          case 'referral_reward':
            this.$router.push({ name: 'sayl-user-portal.login', query: { redirect: 'sayl-front-user.loyalty', referral: this.$basil.get(this.value, 'relatedId') } }).catch(() => {})
            break;
        }
      }

      this.$emit('close')
    },

    async onRead() {
      try {
        await this.$notification.read({ ids: [ this.value.id ]})
      } catch(e) {
        $console.error(e)
      } finally {
        this.key++
        this.$emit('update')
      }
    },
  }
}
</script>
