export default {
  description: {
    source: 'description',
    type: String,
  },
  id: {
    source: 'id',
    type: String,
  },
  poster: {
    source: 'images',
    format: (value) => {
      let ret = value.find(i => i.field_name === 'poster')
      return ret ? ret.url : null
    }
  },
  title: {
    source: 'title',
    type: String,
  },
  url: {
    source: 'url',
    type: String,
  },
}