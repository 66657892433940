import Vue from 'vue'
import SaylStore from '../stores'
import VueRouter from 'vue-router'
import routes from './routes'
import Config from '@/config'
import States from '../states'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: Config.publicPath,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta && to.meta.scrollTop === true) {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  try {
    SaylStore.bootstrap().burger = false
  } catch(e) {}

  next()
})

router.afterEach((to, from, failure) => {
  try {
    if(to && !failure && Config.state === States.APPLICATION_COMPLETE) {
      sayl.injekt.sp.apply('trackPageView')
    }
  } catch(e) {}
})

export default router
