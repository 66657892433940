import { CurryApi } from '@spices/curry'
import config from './config'

import Video from './videos/model'

import { useVideoStore } from './store'

/**
 * Controller class for the entity Nft
 * 
 * @class
 */
export default class VideoController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger

    this._store = store
    this._store.register('video', useVideoStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise 
   * 
   * @param {Object} options
   */
  async init({ }) {
    try {
      return
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS

  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.video().loading
  }

  /**
   * @property {Video} video
   * @readonly
   * @return {Video}
   */
  get video() {
    return this._store.video().video
  }

  /////////////////////////////////////////
  ///           METHODS  
  /**
   * @param {Object} options 
   * @param {String} options.id
   */
  async keepalive({ videoId }) {
    try {
      await this._api.get({ type: 'keepalive', payload: { videoId } })
    } catch(e) {
      throw e
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.id
   */
  async view({ triggerId }) {
    try {
      this._store.video().loading = true
      this._store.video().video = null
      
      let { data } = await this._api.get({ type: 'entity', payload: { triggerId } }) 
      let video = new Video(data)

      this._store.video().video = new Video(data)
      return this._store.video().video
    } catch(e) {
      throw e
    } finally {
      this._store.video().loading = false
    }
  }
}
