import { defineStore } from 'pinia'

export const useVideoStore = defineStore('video', {
  state: () => ({
    // Datas
    video: null,

    // Status
    loading: true,
  }),
})