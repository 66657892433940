<template>
  <transition
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave">
    <aside
      v-show="visible"
      :class="classes">
      <div class="n-burger__content">
        <!-- Header -->
        <header class="n-burger__header">
          <ui-img
            :src="logo"
            alt="Logo"
            class="n-burger__logo"
            v-if="logo"
          />

          <div 
            class="n-burger__logo" 
            v-if="!logo"
          ></div>

          <div class="n-burger__cactions">
            <button
              class="n-burger__close"
              @click="close">
              <span></span>
              <span></span>
            </button>
          </div>
        </header>

        <!-- Body -->
        <div class="n-burger__body">
          <ul class="n-burger__list">
            <!-- Loyalty -->
            <li
              class="n-burger__item"
              v-if="showLoyalty">
              <router-link
                :to="{ name: 'sayl-user-portal.loyalty' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-award n-burger__icon"></i>

                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('user-portal.burger_card_loyalty') }}</h2>
                  <p class="description">{{ $t('user-portal.burger_card_loyalty_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- Challenges -->
            <li
              class="n-burger__item"
              v-if="hasChallenges">
              <router-link
                :to="{ name: 'sayl-user-portal.challenges' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-flag n-burger__icon"></i>

                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('user-portal.burger_challenges') }}</h2>
                  <p class="description">{{ $t('user-portal.burger_challenges_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- Wallet -->
            <li
              class="n-burger__item"
              v-if="hasWallet">
              <router-link
                :to="{ name: 'sayl-user-portal.wallet' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-folders n-burger__icon"></i>

                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('user-portal.burger_wallet') }}</h2>
                  <p class="description">{{ $t('user-portal.burger_wallet_description') }}</p>
                </div>
              </router-link>
            </li>

             <!-- Wallet -->
             <li
              class="n-burger__item"
              v-if="isLogged">
              <router-link
                :to="{ name: 'sayl-user-portal.poaps' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-target n-burger__icon"></i>

                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('user-portal.burger_poaps') }}</h2>
                  <p class="description">{{ $t('user-portal.burger_poaps_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- User -->
            <li
              class="n-burger__item"
              v-if="isLogged">
              <router-link
                :to="{ name: 'sayl-user-portal.home' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-user n-burger__icon"></i>

                <div class="n-burger__item-label">
                  <h2 class="title">{{ userName }}</h2>
                  <p class="description">{{ $t('user-portal.burger_user_description') }}</p>
                </div>
              </router-link>
            </li>

            <!-- User -->
            <li
              class="n-burger__item"
              v-if="!isLogged">
              <router-link
                :to="{ name: 'sayl-user-portal.home' }"
                class="n-burger__link n-header__burger-bag">
                <i class="icon-user n-burger__icon"></i>

                <div class="n-burger__item-label">
                  <h2 class="title">{{ $t('user-portal.burger_login') }}</h2>
                  <p class="description">{{ $t('user-portal.burger_login_description') }}</p>
                </div>
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Footer -->
        <footer class="n-burger__footer">
          <div
            class="n-burger__footerrow logout"
            v-if="isLogged">
            <div
              class="footer__link n-burger__link n-header__burger-bag"
              @click="onLogout">
              <i :class="`icon-logout n-burger__icon`"></i>
              <div class="n-burger__item-label">
                <h2 class="title">{{ $t('user-portal.burger_logout') }}</h2>
                <p class="description">{{ $t('user-portal.burger_logout_description') }}</p>
              </div>
            </div>
          </div>

          <div class="n-burger__footerrow legal">
            <div class="legal__content">
              <forms-select
                class="n-burger__langs"
                :placeholder="$t('user-portal.burger_lang_placeholder')"
                :options="langs"
                :value="userLang"
                @change="onChangeLang"
              />

              <ul class="n-burger__legal">
                <li
                  v-for="item in legal"
                  :key="item.label"
                  class="n-burger__legalitem --action">
                  <a
                    :href="item.href"
                    target="_blank"
                    rel="nofollow"
                    v-if="item.href"
                  >{{ item.label }}</a>

                  <a
                    @click="item.click"
                    v-if="item.click"
                  >{{ item.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>

      <!-- Blanket -->
      <popins-blanket
        ref="blanket"
        :disabled="false"
        @click="close"
        :visible="visible"
      />
    </aside>
  </transition>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import UiImg from '../ui/img'
import LayoutModal from '../layouts/modal'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'SaylFrontBurger',

  components: {
    LayoutModal,
    UiImg,
  },

  inject: [
    '$core',
    '$challenge',
    '$embed',
    '$localStorage',
    '$loyalty',
    '$user',
    '$wallet',
  ],

  mixins: [
    MixinCurrency
  ],

  data() {
    return {
      showAdminModal: false,
      adminPin: null,

      bootstrap: SaylStore.bootstrap(),
      challengeStore: SaylStore.challenge()
    }
  },

  computed: {
    // Pinia stores Tmp
    visible() {
      return this.bootstrap.burger
    },

    locales() {
      return this.bootstrap.locales
    },

    i18n() {
      return this.bootstrap.i18n
    },
    // End Store

    classes() {
      return {
        'n-burger': true,
        '-is-active': true
      }
    },
    
    cookiesDisabled() {
      return this.$basil.get(this.bootstrap, 'cookies.disabled', false)
    },

    hasChallenges() {
      return this.challengeStore.active
    },

    hasLoyalty() {
      return !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null))
    },

    hasWallet() {
      return [
        this.$basil.get(this.user, 'hasWallet', false) === true,
        // !this.$basil.isNil(this.$wallet.wallet)
      ].filter(s => s === false).length === 0
    },

    isLogged() {
      return !this.$basil.get(this.user, 'anonymous', true)
    },

    langs() {
      return this.locales.map((l) => {
        return {
          label: this.$t(`user-portal.lang_${l.iso.toLowerCase()}`),
          value: l.iso
        }
      })
    },

    legal() {
      let terms = this.$basil.get(this.$embed, 'embed.generalConditions', null)
      let privacy = this.$basil.get(this.$embed, 'embed.privacyPolicy', null)

      return [
        { 
          label: this.$t('user-portal.burger_legal_term_and_conditions'), 
          href: terms ? terms : this.$t('user-portal.burger_legal_term_and_conditions_link') 
        },
        { 
          label: this.$t('user-portal.burger_legal_privacy_policy'), 
          href: privacy ? privacy : this.$t('user-portal.burger_legal_privacy_policy_link') 
        },
        !this.cookiesDisabled ? {
          label: this.$t('user-portal.burger_legal_cookies_settings'),
          click: () => this.$bus.$emit('cookies-settings')
        } : null
      ].filter(l => l != null)
    },

    logo() {
      return this.$basil.get(this.$embed, 'embed.logo', null)
    },

    showLoyalty() {
      return this.hasLoyalty
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      let ret = this.user != null ?
                this.locales.find(l => l.lang === this.user.lang) :
                this.locales.find(l => l.lang ===  this.shop.primary_language)

      return this.$basil.get(ret, 'iso', null)
    },

    userName() {
      let firstname = this.$basil.get(this, 'user.firstname');
      let lastname = this.$basil.get(this, 'user.lastname');

      return firstname + ' ' + lastname
    },
  },

  watch: {
    burger(value) {
      value ? this.show() : this.hide()
    },
  },

  methods: {
    close() {
      this.bootstrap.burger = false
    },

    onLogout() {
      let logout = async () => {
        try {
          await this.$user.reset()
          this.$router.push({ name: 'sayl-user-portal.login' })
        } catch(e) {
          $console.error(e)
        } finally {
          this.close()
        }
      }

      this.bootstrap.burger = false

      this.$confirm({
        title: this.$t('user-portal.logout_burger_confirmation_title'),
        description: this.$t('user-portal.logout_burger_confirmation_description'),
        onPrimary: logout
      })
    },

    //
    // Transitions
    //
    afterEnter(el) {
      this.active = true;
    },

    beforeLeave(el) {
      this.active = false;
    },

    delay(delay) {
      return new Promise(function(resolve) {
        setTimeout(resolve, delay);
      })
    },

    async enter(el, done) {
      try {
        await this.delay(500)
        done()
      } catch(e) {}
    },

    async leave(el, done) {
      try {
        await this.delay(500)
        done()
      } catch(e) {}
    },

    show() {
      document.body.classList.add('-no-scroll');
    },

    hide() {
      document.body.classList.remove('-no-scroll');
    },

    async onChangeLang(value) {
      this.showLocales = false
      this.i18n.setLocale(value)
      
      try {
        await this.$core.changeLang({ locale: value })
        this.$bus.$emit('change-lang')
      } catch(e) {
        $console.error(e)
      }
    },
  },
}
</script>
