import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import ChallengeCompletion from './completions/model'
import Config from './config'

// Models
import Challenge from './challenges/model'
import ChallengeSettings from './settings/model'

import { useChallengeStore } from './store'

/**
 * Controller class for the entity User
 * @class
 */
export default class ChallengeController {
  /**
   * @constructor
   */
  constructor({ logger, localStorage, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: Config, transports })

    this._logger = logger
    this._localStorage = localStorage
    this._store = store

    this._store.register('challenge', useChallengeStore)
  }

  //////////////////////////////////////////
  // GETTERS
  /**
   * @property {Boolean} 
   * @readonly
   * @return {Boolean}
   */
  get active() {
    return this._store.challenge().active
  }

  /**
   * @property {Array} challenges
   * @readonly
   * @return {Array}
   */
  get challenges() {
    return this._store.challenge().challenges
  }

  /**
   * @property {Challenge} challenge
   * @readonly
   * @return {Challenge}
   */
  get challenge() {
    return this._store.challenge().challenge
  }

  /**
   * @property {Array<ChallengeCompletion>} completions
   * @readonly
   * @return {Array<ChallengeCompletion>}
   */
  get completions() {
    return this._store.challenge().completions
  }

  /**
   * @property {Boolean} 
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.challenge().loading
  }

  /**
   * @property {ChallengeSettings} settings
   * @readonly
   * @return {ChallengeSettings}
   */
  get settings() {
    return this._store.challenge().settings
  }

  //////////////////////////////////////////
  // METHODS
  //////////////////////////////////////////
  async init() {
    try {
      this._store.challenge().loading = true
      await this.getSettings() 

      if(this.settings && this.settings.status !== 404) {
        this._store.challenge().active = true
      }
      return 
    } catch(e) {
      throw e
    } finally {
      this._store.challenge().loading = false
    }
  }
  
  async getChallenges() {
    try {
      this._store.challenge().loading = true
      
      let { data } = await this._api.get({ type: 'collection' })
      this._store.challenge().challenges = data ? data.map((c) => new Challenge(c)) : []
      return this._store.challenge().challenges
    } catch(e) {
      throw e
    } finally {
      this._store.challenge().loading = false
    }
  }

  async getCompletionsByChallenge({ id = null }) {
    try {
      this._store.challenge().loading = true

      let { data } = await this._api.get({ type: 'challengeCompletions', payload: { id } })
      this._store.challenge().completions = data ? data.map((c) => new ChallengeCompletion(c)) : []
      return this._store.challenge().completions
    } catch(e) {
      throw e
    } finally {
      this._store.challenge().loading = false
    }
  }

  async getCompletions({ limit = -1, page = 1 }) {
    try {
      this._store.challenge().loading = true

      let { data } = await this._api.get({ type: 'challengesCompletions', payload: { limit, page } })
      this._store.challenge().completions = data ? data.map((c) => new ChallengeCompletion(c)) : []
      return this._store.challenge().completions
    } catch(e) {
      throw e
    } finally {
      this._store.challenge().loading = false
    }
  }

  async getSettings() {
    try {
      this._store.challenge().loading = true

      if(basil.isNil(this._store.challenge().settings)) {
        let { data } = await this._api.get({ type: 'settings', payload: {} })
        this._store.challenge().settings = data ? new ChallengeSettings(data) : null
      }

      return this._store.challenge().settings
    } catch(e) {
      if(basil.get(e, 'status', 500) === 404) {
        this._store.challenge().settings = { status: 404 }
        return this._store.challenge().settings
      }
      throw e
    } finally {
      this._store.challenge().loading = false
    }
  }

  async view({ id }) {
    try {
      this._store.challenge().loading = true
    
      let { data } = await this._api.get({ type: 'entity', payload: { id }})
      this._store.challenge().challenge = data ? new Challenge(data) : []
      return this._store.challenge().challenge
    } catch(e) {
      throw e
    } finally {
      this._store.challenge().loading = false
    }
  }
}
