export default  {
  fqn: 'ifm.user',
  endpoints: {
    address: {
      delete: '/user/address/$id',
      post: '/user/address',
      put: '/user/address/$id'
    },

    anonymize: {
      post: '/user/anonymize'
    },

    countries: {
      get: '/countries?locale=$locale'
    },

    entity: {
      get: '/user',
      post: '/user/reset?locale=$locale',
      put: '/user'
    },
    
    lang: {
      put: '/user/language'
    },

    login: {
      post: '/user/login'
    },

    pass: {
      get: '/user/card/token'
    },

    password: {
      post: '/user/lost-password',
      put: '/user/password'
    },

    propagate: {
      post: '/user/propagate'
    },

    resetPassword: {
      post: '/user/reset-password'
    },

    short: {
      post: '/user/short'
    },
    
    subscribe: {
      post: '/user/subscribe'
    },

    vouchers: {
      get: '/user/vouchers?limit=$limit&page=$page'
    },

    avatar: {
      post: '/user/avatar',
      delete: '/user/avatar'
    },

    transport: 'cp',
  },
}
