import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * RedeemableVoucher class for a loyalty program
 * 
 * @class
 */
export default class RedeemableVoucher extends CurryModel{
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }
}
