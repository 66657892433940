import PoapLocation from "../poap-locations/model"

export default {
  coordinates: {
    source: 'coordinates',
    type: Array
  },
  created: {
    source: 'created',
    type: Date
  },
  distance: {
    format: value => {
      return value != null ? value : null;
    },
    source: 'distance'
  },
  id: {
    source: 'id',
    type: String
  },
  image: {
    source: 'image',
    type: String
  },
  modified: {
    source: 'modified',
    type: Date
  },
  poapLocation: {
    source: 'poap_location',
    type: PoapLocation
  },
  poapLocationId: {
    source: 'poap_location_id',
    type: String
  },
  transactionId: {
    source: 'transaction_id',
    type: String
  },
  transactionTimestamp: {
    source: 'transaction_timestamp',
    type: Number
  },
  userId: {
    source: 'user_id',
    type: String
  }
}