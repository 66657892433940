import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * NFT model
 * 
 * @class
 */
export default class Poap extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }

  get assetUrl() {
    return [
      this.collectibleImage || null
    ].find(a => a != null)
  }
}
