// import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import config from './config'

// Models
import BalanceHistory from './balances/history/model'
import Loyalty from './programs/model'

import { useLoyaltyStore } from './store'

/**
 * Controller class for the entity Loyalty
 * @class
 */
export default class LoyaltyController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger

    this._store = store
    this._store.register('loyalty', useLoyaltyStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise the Loyalty program
   * 
   * @param {Object} options
   * @param {Object} options.program
   */
  async init({ program }) {
    try {
      this._store.loyalty().program = new Loyalty(program)
      return
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {BalanceHistory} history
   * @readonly
   * @return {BalanceHistory}
   */
  get balanceHistory() {
    return this._store.loyalty().balanceHistory
  }

  /**
   * @property {Array} cards
   * @readonly
   * @return {Array}
   */
  get cards() {
    return this._store.loyalty().cards
  }

  /**
   * @property {Object} cardsMeta
   * @readonly
   * @return {Object}
   */
  get cardsMeta() {
    return this._store.loyalty().cardsMeta
  }

  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.loyalty().loading
  }

  /**
   * @property {Loyalty} program
   * @readonly
   * @return {Object}
   */
  get program() {
    return this._store.loyalty().program
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Get the balance for a user
   * @param {Object} payload 
   */
  async getBalanceHistory(payload) {
    try {
      this._store.loyalty().loading = true
      let { data } = await this._api.get({ type: 'collection', payload })

      let pagination = data.meta && data.meta.pagination != null ? data.meta.pagination : {}
      this._store.loyalty().balanceHistory = data.data ? 
        new BalanceHistory({ items: data.data }) : new BalanceHistory({ items: [] })

      return { balance: this._store.loyalty().balanceHistory, pagination }
    } catch(e) {
      throw e
    } finally {
      this._store.loyalty().loading = false
    }
  }

  /**
   * Get the cards already linked to the user
   * @param {Object} payload
   */
  async getCards(payload) {
    try {
      this._store.loyalty().loading = true

      let { data } = await this._api.get({ type: 'cards', payload })
  
      this._store.loyalty().cards = data.data || data
      this._store.loyalty().cardsMeta = data.meta || {}

      // If troubble, test this method
      // if (!basil.isNil(payload.limit) && payload.limit === -1) {
      //   this._cards = cards;
      // } else {
      //   this._cards = cards.data;
      // }
      // this._cardsMeta = cards.meta || {};

      return this._store.loyalty().cards
    } catch(e) {
      throw e
    } finally {
      this._store.loyalty().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.token
   */
  async linkCard({ token }) {
    try {
      this._store.loyalty().loading = true
      
      let { data } = await this._api.post({ type: 'linkCard', payload: { item: { token } } })
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.loyalty().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.token
   */
  async tradeVoucher({ voucherId }) {
    try {
      this._store.loyalty().loading = true

      let { data } = await this._api.post({ type: 'vouchers', payload: { item: { voucher_id: voucherId } } })
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.loyalty().loading = false
    }
  }

  /**
   * 
   * @param {Object} options 
   * @param {String} options.token
   */
  async unusedCard({ token }) {
    try {
      this._store.loyalty().loading = true

      let { data } = await this._api.post({ type: 'unusedCard', payload: { item: { token } } })
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.loyalty().loading =  false
    }
  }
}
