export default {
  created: {
    source: 'created',
    type: Date
  },
  escrowDate: {
    source: 'escrow_date',
    type: String
  },
  escrowTransactionId: {
    source: 'escrow_transaction_id',
    type: String
  },
  executionDate: {
    source: 'execution_date',
    type: String
  },
  fromSaylAppUserId: {
    source: 'from_sayl_app_user_id',
    type: String
  },
  fromWalletAddress: {
    source: 'from_wallet_address',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  isInEscrow: {
    source: 'is_in_escrow',
    type: String
  },
  lang: {
    source: 'lang',
    type: String
  },
  modified: {
    source: 'modified',
    type: Date
  },
  nftId: {
    source: 'nft_id',
    type: String
  },
  projectId: {
    source: 'project_id',
    type: String
  },
  revertTransferOn: {
    source: 'revert_transfer_on',
    type: String
  },
  serial: {
    source: 'serial',
    type: Number
  },
  status: {
    source: 'status',
    type: Number
  },
  toEmail: {
    source: 'to_email',
    type: String
  },
  toSaylAppUserId: {
    source: 'to_sayl_app_user_id',
    type: String
  },
  toWalletAddress: {
    source: 'to_wallet_address',
    type: String
  },
  tokenHash: {
    source: 'token_hash',
    type: String
  },
  tokenId: {
    source: 'token_id',
    type: String
  },
  transactionId: {
    source: 'transaction_id',
    type: String
  }
}