export default {
  fqn: 'ifm.referral',

  endpoints: {
    collection: {
      get: '/user/referral/rewards?limit=$limit&page=$page'
    },
    
    entity: {
      get: '/user/referral',
      post: '/user/referral',
    },
  },
  
  transport: 'cp'
}
