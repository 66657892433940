import PoapLocation from '../poap-locations/model';

export default {
  id: {
    source: 'id',
    type: String
  },
  poapLocations: {
    source: 'poap_locations',
    format: (value) => value.map(item => new PoapLocation(item))
  },
  poaps: {
    source: 'poaps',
    type: Array
  }
}