import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import Config from './config'

// Models
import Notification from './notifications/model'

import { useNotificationStore } from './store'

/**
 * Controller class for the entity Notification
 * 
 * @class
 */
export default class NotificationController {
  /**
   * @constructor
   */
  constructor({ logger, localStorage, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: Config, transports })

    this._logger = logger
    this._localStorage = localStorage

    this._store = store
    this._store.register('notification', useNotificationStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialize the notifications
   * 
   * @param {Object} options
   * @param {Object} options.args
   * @param {Object} options.user
   * 
   * @returns
   */
  async init({ args, user }) {
    try {
      this._store.notification().loading = true

      let saylNotification = basil.get(args, 'sayl-notification', null)
      if(!basil.isNil(saylNotification)) {
        await this.getNotification({ saylNotification })
      }

      if(user.isLogged) {
        await this.getNotifications({})
      }

      return
    } catch(e) {
      throw e
    } finally {
      this._store.notification().loading = false
    }
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Boolean} loading
   * @readonly
   * @returns {Boolean}
   */
  get loading() {
    return this._store.notification().loading
  }

  /**
   * @property {Notification}
   * @readonly
   * @returns {Notification}
   */
  get notification() {
    return this._store.notification().notification
  }

  /**
   * @property {Array<Notification>}
   * @readonly
   * @returns {Array<Notification>}
   */
  get notifications() {
    let ret = this._store.notification().notifications || []

    return this.notification ? 
      ret.filter(n => this.notification.id !== n.id) : ret
  }

  /**
   * @property {Object}
   * @readonly
   * @returns {Object}
   */
  get pagination() {
    return this._store.notification().pagination
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Fetch one notification base of it's ID
   * 
   * @param {Object} options
   * @param {String} options.saylNotification 
   * 
   * @return {Notification}
   */
  async getNotification({ saylNotification = null }) {
    try {
      let { data } = await this._api.get({ type: 'entity', payload: { token: saylNotification }})
      this._store.notification().notification = new Notification(data)
      return this._store.notification().notification
    } catch(e) {
      throw e
    }
  }

  /**
   * Fetch the list of pending notifications
   * 
   * @param {Object} options
   * @param {Number} options.limit 
   * @param {Number} options.page 
   * 
   * @return {Array<Notification>}
   */
  async getNotifications({ limit = 15, page = 1 }) {
    try {
      this._store.notification().loading = true

      let { data } = await this._api.get({ type: 'collection', payload: { limit, page } })

      this._store.notification().notifications = data && data.data ? 
        data.data.map(d => new Notification(d)) : []

      this._store.notification().pagination = data && data.meta && 
        data.meta.pagination ? data.meta.pagination : {}

      return this._store.notification().notifications
    } catch(e) {
      throw e
    } finally {
      this._store.notification().loading = false
    }
  }

  /**
   * Read some notifications based on their ids.
   * This will removed them from the list afterward because of the refecthing of the list
   * 
   * @param {Object} options
   * @param {Array<String>} options.ids 
   * 
   * @return {Array<Notification>}
   */
  async read({ ids }) {
    try {
      this._store.notification().loading = true
      
      await this._api.post({ type: 'entity', payload: { item: { ids } }})

      if(this.notification != null && ids.includes(this.notification.id)) {
        this._store.notification().notification = null
      }

      let n = await this.getNotifications({})
      return n
    } catch(e) {
      throw e
    } finally {
      this._store.notification().loading = false
    }
  }
}
