import { CurryApi } from '@spices/curry'
import UserConfig from './config'

// Models
import User from './users/model'

import { useUserStore } from './store'
import Voucher from './vouchers/model'

/**
 * Controller class for the entity User
 * @class
 */
export default class UserController {
  /**
   * @constructor
   */
  constructor({ emitter, logger, localStorage, notification, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: UserConfig, transports })
    this._emitter = emitter
    this._logger = logger
    this._localStorage = localStorage
    this._notificationController = notification

    this._store = store
    this._store.register('user', useUserStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise the user
   * 
   * @param {Object} options
   * @returns {Promise}
   */
  async init({ }) {
    try {
      this._store.user().loading = true
      await this.getCountries()
      let { data } = await this._api.get({ type: 'entity' })
      return this._userFallbackAction({ action: 'user.update', user: data }, false)
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  _userFallbackAction({ action = null, user }, notifications = true) {
    if(user) {
      this._store.user().user = new User(user)
    }

    if(this._emitter && action) {
      this._emitter.emit(action, this._store.user().user)
    }

    if(notifications) {
      this._notificationController.getNotifications({})
    } 

    return this._store.user().user
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Array} countries
   * @readonly
   * @return {Array}
   */
  get countries() {
    return this._store.user().countries
  }
  
  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.user().loading
  }

  /**
   * @property {Object} user
   * @readonly
   * @return {Object}
   */
  get user() {
    return this._store.user().user
  }

  /**
   * @property {Array} vouchers
   * @readonly
   * @return {Array<Voucher>}
   */
  get vouchers() {
    return this._store.user().vouchers
  }

  /////////////////////////////////////////
  ///           METHODS
  async view() {
    try {
      this._store.user().loading = true
      
      let { data } = await this._api.get({ type: 'entity' })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  async storeAvatar(item) {
    try {
      let { data } = await this._api.post({ type : 'avatar', payload: { item } })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch (e) {
      throw e
    }
  }

  async deleteAvatar() {
    try {
      await this._api.delete({ type : 'avatar' })
      let { data } = await this._api.get({ type: 'entity' })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch (e) {
      throw e
    }
  }
  
  ////// Addresses //////
  /**
   * Add an address
   *
   * @param {Object} options
   * @param {Object} options.address
   * @returns {Promise}
   */
  async addAddress({ address }) {
    try {
      this._logger.info('user.addAddress')
      this._logger.debug('-address', address)

      this._store.user().loading = true
      
      let { data } = await this._api.post({ type: 'address', payload: { item: address.toAPI ? address.toAPI() : address } })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * @param {Object} options
   * @param {String} options.id
   * 
   * @return {Promise}
   */
  async removeAddress({ id }) {
    try {
      this._logger.info('user.removeAddress')
      this._logger.debug('-address', id)
      
      this._store.user().loading = true
      
      let { data } = await this._api.delete({ type: 'address', payload: { id } })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * @param {Object} options
   * @param {Address} options.address
   * 
   * @return {Promise} - Resolve the add methods or the update methods if the address already exists
   */
  setAddress({ address }) {
    return address.id != null ?
      this.updateAddress({ address }) :
      this.addAddress({ address })
  }

  /**
   * Update an address
   *
   * @param {Object} options
   * @param {Object} options.address
   * 
   * @returns {Promise}
   */
  async updateAddress({ address }) {
    try {
      this._logger.info('user.updateAddress')
      this._logger.debug('-address', address)

      this._store.user().loading = true
      
      let { data } = await this._api.put({ type: 'address', payload: { id: address.id, item: address.toAPI ? address.toAPI() : address } })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Fetch the countries available for this user
   * 
   * @returns {Array<Object>}
   */
  async getCountries() {
    if(this.countries != null && this.countries.length > 0) {
      return this._store.user().countries
    }

    try {
      let { data } = await this._api.get({ type: 'countries' })
      this._store.user().countries = data
      return this._store.user().countries
    } catch(e) {
      throw e
    }
  }
  ////// END Addresses //////

  ////// Password //////
  /**
   * Second step of the recovery password
   * Allow the user to reset his password, if the token is provided
   * 
   * @param {Object} options 
   * @param {String} options.email 
   * @param {String} options.password 
   * @param {String} options.passwordConfirm
   * @param {String} options.token
   * 
   * @returns {Promise}
   */
  async changePassword({ email, password, passwordConfirm, token }) {
    try {
      this._store.user().loading = true
      let { data } = await this._api.post({ type: 'resetPassword', payload: { item: { email, password, password_confirmation: passwordConfirm, token } } })
      return this._userFallbackAction({ action: 'user.update', user: data }, false)
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Start the lost password system.
   * 
   * @param {Object} options
   * @param {String} options.email
   *  
   * @returns {Promise}
   */
  async lostPassword({ email }) {
    try {
      this._store.user().loading = true
      
      await this._api.post({ type: 'password', payload: { item: { email } }})
      return 
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Update the password by providing the old password, and the new password.
   * 
   * This is not the same as the lost password mechanism. You need to be logged and
   * remember you old password
   * 
   * @param {Object} options
   * @param {String} options.password
   * @param {String} options.newPassword
   * @param {String} options.newPasswordConfirmation
   * 
   * @returns {Promise}
   */
  async updatePassword({ password, newPassword, newPasswordConfirmation }) {
    try {
      this._store.user().loading = true
      await this._api.put({ type: 'password', payload: { item: { password,new_password: newPassword,new_password_confirmation: newPasswordConfirmation } }})
      return this._userFallbackAction({}, false)
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }
  ////// END Password //////

  ////// Managements //////
  /**
   * Anynomise the user to respect GDPR
   * 
   * @returns {Object}
   */
  async anonymize() {
    try {
      let { data } = await this._api.post({ type: 'anonymize'})
      return data
    } catch(e) {
      throw e
    }
  }
  
  /**
   * Change on the server the user lang
   *
   * @param {Object} locale - Choosen locale to inject in the user
   * @return {Promise} - Resolve the data returned if successful, otherwise reject
   */
  async changeLang({ locale }) {
    try {
      this._logger.info('user.changeLang')
      this._logger.debug('-lang', locale)

      this._store.user().loading = true

      let { data } = await this._api.put({ type: 'lang', payload: { item: { locale } }})
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Login methods for the user
   * 
   * @param {Object} options
   * @param {String} options.email
   * @param {String} options.password
   * 
   * @returns {Promise}
   */
  async login({ email, password, redirect = null, targetEmbedId = null }) {
    try {
      this._store.user().loading = true

      let { data } = await this._api.post({ type: 'login', payload: { item: { email, password, redirect, target_embed_id: targetEmbedId }} })
      return this._userFallbackAction({ action: 'user.login', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  async propagate({ redirect = null, targetEmbedId = null }) {
    try {
      this._store.user().loading = true

      await this._api.post({ type: 'propagate', payload: { item: { redirect, target_embed_id: targetEmbedId }}})
      return
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Reset the current user
   * 
   * @returns {User}
   */
  async reset() {
    try {
      this._logger.info('user.reset')
      this._store.user().loading = true
      
      let locale = this._localStorage.locale
      let { data } = await this._api.post({ type: 'entity', payload: { item: { locale } } })
      
      this._store.notification().notification = null
      this._store.notification().notifications = []

      this._localStorage.user = null

      return this._userFallbackAction({ action: 'user.logout', user: data }, false)
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Allow the user to create an account
   * 
   * @param {Object} options
   * @param {User} options.user
   * @param {String} options.password
   * @param {String} options.passwordConfirm
   * 
   * @returns {User}
   */
  async subscribe({ user, password, redirect = null, targetEmbedId = null }) {
    try {
      this._store.user().loading = true
      
      let item = user.toAPI()
      item.password = password
      item.redirect = redirect
      item.target_embed_id = targetEmbedId
      
      let { data } = await this._api.post({ type: 'subscribe', payload: { item } })
      return this._userFallbackAction({ action: 'user.subscribe', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }

  /**
   * Update a user with the provided information
   *
   * @return {User} - Resolve the data returned if successful, otherwise reject
   */
  async update({ user = this._store.user().user.toAPI() }) {
    try {
      this._logger.info('user.update')
      this._store.user().loading = true
      
      let { data } = await this._api.put({ type: 'entity', payload: { item: user } })
      return this._userFallbackAction({ action: 'user.update', user: data })
    } catch(e) {
      throw e
    } finally {
      this._store.user().loading = false
    }
  }
  ////// END Managements //////


  ////// UTILS //////
  /**
   * Get the PK Pass token. This will allow the user to 
   * download his/her pkpass
   * 
   * @param {Object}
   * 
   * @returns {String}
   */
  async getTokenForPkPass(args = { }) {
    try {
      let { data } = await this._api.get({ type: 'pass', payload: args })
      return data.token ? data.token : null
    } catch(e) {
      throw e
    }
  }

  /**
   * Get a shortned URL for any URL provided
   * 
   * @param {Object} data
   * @param {String} data.url
   * 
   * @returns {String}
   */
  async shorten({ url }) {
    try {
      let { data } = await this._api.post({ type: 'short', payload: { item: { url }}})
      return data.url ? data.url : url
    } catch(e) {
      return url
    }
  }

  /**
   * Fetch the vouchers allocated to the connected user
   * @param {*} args 
   * @returns 
   */
  async getVouchers(args = { limit: 9999, page: 1 }) {
    try {
      let { data } = await this._api.get({ type: 'vouchers', payload: args })
      this._store.user().vouchers = data && data.data ? data.data.map(v => new Voucher(v)) : []
      return this._store.user().vouchers
    } catch(e) {
      throw e
    }
  }
}
