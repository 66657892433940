export default {
  created: { source: 'created', type: Date },
  id: { source: 'id', type: String },
  isRead: { source: 'is_read', type: Boolean },
  label: { source: 'label', type: String },
  message: { source: 'message', type: String },
  modified: { source: 'modified', type: Date },
  relatedEntity: { source: 'related_entity', type: String },
  relatedId: { source: 'related_id', type: String },
  text: { source: 'text', type: String },
}
