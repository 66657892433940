import { CurryApi } from '@spices/curry'
import Config from './config'

import Error from '../error'

// Models
import Embed from './embeds/model.js'

import { useEmbedStore } from './store'


/**
 * Controller class for the entity Embed
 * 
 * @class
 * @author Valentin Gregoire <valentin@infinity-mobile.io>
 */
export default class EmbedController {
  /**
   * Create a new instance of the Embeds
   * 
   * @param {Object} options
   * @param {Object} options.logger
   * @param {object} options.localStorage - LocalStorage instance
   * @param {object} options.store - SaylStore instance
   * @param {object} options.transports - Available transports (Axios instance, pusher instance, ...)
   */
  constructor({ logger, localStorage, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: Config, transports })
    this._logger = logger
    this._localStorage = localStorage

    this._store = store
    this._store.register('embed', useEmbedStore)
  }

  //////////////////////////////////////////
  // GETTERS
  /** 
   * @property {Embed}
   * @readonly
   * @return {Embed}
   */
  get embed() { 
    return this._store.embed().embed
  }

  /**
   * @property {Boolean} 
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.embed().loading
  }

  /**
   * Initialize the embed
   * 
   * @param {Object} options 
   * @param {Object} options.args 
   * @param {Object} options.embed 
   * @param {String} options.id
   * @param {Locale} options.locale
   */
  async init({ embed, id, locale }) { 
    this._logger.group('embed.init')

    try {
      this._store.embed().loading = true

      if(embed) {
        this._store.embed().embed = new Embed(embed)
      } else {
        let { data } = await this._api.get({ type: 'entity', payload: { id, locale }})
        this._store.embed().embed = new Embed(data)
      }

      if(this._store.embed().embed.active === false) {
        throw Error.EMBED_NOTACTIVE
      }

      return this._store.embed().embed
    } catch(e) {
      throw e
    } finally {
      this._store.embed().loading = false
      this._logger.groupEnd('embed.init')
    }
  }

  async reset({ locale }) {
    try {
      await this.init({ id: this._store.embed().embed.id, locale })
      return 
    } catch(e) {
      throw e
    }
  }
}
