import { defineStore } from 'pinia'

export const useChallengeStore = defineStore('challenge', {
  state: () => ({
    // Datas
    challenges: [],
    challenge: null,
    completions: [],
    settings: null,

    // Status
    active: false,
    loading: true,
  }),
})