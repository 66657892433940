<template>
  <!-- Popin Locales -->
  <popins-popin
    class="locales"
    position="bottom-left"
    ref="modalLocales"
    :style="style"
    :target="target"
    :visible="visible"
    v-click-outside="onClose">
    <ul class="locales__list">
      <li
        :class="{ 'locales__item': true, '-is-active': lang.value === userLang }"
        :key="i"
        v-for="(lang, i) in langs">
        <div
          class="locales__locale"
          @click.stop.prevent="onChangeLang(lang.value)"
        >{{ lang.label }}</div>
      </li>
    </ul>
  </popins-popin>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import { ClickOutside } from '@spices/pepper'

export default {
  name: 'ModalLocales',

  directives: {
    ClickOutside
  },

  inject: [
    '$core',
    '$user'
  ],

  props: {
    target: {},

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      debounce: null,
      debounceResize: null,
      canClose: false,
      key: 1,
      screenWidth: 744,

      bootstrap: SaylStore.bootstrap()
    }
  },

  computed: {
    // Pinia stores Tmp
    locales() {
      return this.bootstrap.locales
    },

    i18n() {
      return this.bootstrap.i18n
    },
    // End Store

    langs() {
      return this.locales.map((l) => {
        return {
          label: this.$t(`user-portal.lang_${l.iso.toLowerCase()}`),
          value: l
        }
      })
    },

    style() {
      let ref = this.visible ? this.$refs.modalLocales : null
      if(ref) {
        if(this.screenWidth > 744) {
          return `left: ${ref.left - 160}px; right: ${ref.right - 160}px`
        } else if(this.screenWidth > 425) {
          return `left: unset; right: 1rem`
        } else {
          return `left: 0px; right: 0px`
        }
      }
      return ``
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      return this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },
  },

  watch: {
    visible: {
      handler() {
        if(this.visible) {
          clearTimeout(this.debounce)
          this.debounce = setTimeout(() => this.canClose = true, 500)

          let ref = this.$refs.modalLocales
          if(ref) {
            ref.left = ref.left - 160
          }
        }
      }
    }
  },

  methods: {
    onClose() {
      this.$emit('close')
    },

    onChangeLang(value) {
      this.$emit('update', value)
    },

    resize() {
      clearTimeout(this.debounceResize)
      this.debounceResize = setTimeout(() => {
        this.screenWidth = document.body.clientWidth
      }, 25)
    },
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
