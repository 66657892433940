import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * NFT model
 * 
 * @class
 */
export default class SaylNFT extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }

  get claimableAttributes() {
    let ret = this.claimableAttributesRaw

    if(ret && ret.length > 0) {
      return ret.map(att => {
        let type = 'system'

        switch(att.type) {
          case 'string':
            type = 'property'
            break

          case 'number': 
            type = 'stat'
            break
        }

        return {
          type,
          name: att.label,
          value: att.type === 'timestamp' ? new Date(att.value).toISOString() : att.value.toString()
        }
      }).filter(r => {
        return [
          // r.type != 'system', 
          r.name !== 'Token Type ID',
          r.name !== 'Mint Number'
        ].find(v => v === false) == null
      })
    }

    return []
  }
}
