import Voucher from '../../users/vouchers/model'

export default {
  chain: {
    source: 'chain',
    type: String
  },
  created: {
    source: 'created',
    type: Date
  },
  customerId: {
    source: 'customer_id',
    type: String
  },
  from: {
    source: 'from',
    type: String
  },
  generation: {
    source: 'generation',
    type: Object
  },
  id: {
    source: 'id',
    type: String
  },
  lastGenerationDate: {
    source: 'last_generation_date',
    type: String,
    format: (value) => {
      return value != null ? new Date(value) : null
    }
  },
  modified: {
    source: 'modified',
    type: Date
  },
  nameBase: {
    source: 'name',
    type: Object
  },
  nextRunAt: {
    source: 'next_run_at',
    type: String,
    format: (value) => {
      return value != null ? new Date(value) : null
    }
  },
  nftId: {
    source: 'nft_id',
    type: String
  },
  projectId: {
    source: 'project_id',
    type: String
  },
  reference: {
    source: 'reference',
    type: String
  },
  slug: {
    source: 'slug',
    type: String
  },
  smartcontractAddress: {
    source: 'smartcontract_address',
    type: String
  },
  status: {
    source: 'status',
    type: String
  },
  template: {
    source: 'template',
    type: Voucher
  },
  to: {
    source: 'to',
    type: String
  },
  tokenHashes: {
    source: 'token_hashes',
    type: Array
  },
  tokenIds: {
    source: 'token_ids',
    type: Array
  },
  type: {
    source: 'type',
    type: String
  }
}