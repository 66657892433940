<template>
  <header
    v-bind="attrs"
    v-if="enabled">
    <div
      class="n-header__container"
      v-click-outside="outside">
      <!-- first -->
      <div class="n-header__group n-header__first">
        <router-link :to="logoHref">
          <ui-img
            alt=""
            class="n-header__logo"
            :src="logo"
          />
        </router-link>
      </div>

      <!-- second -->
      <div
        v-if="!disableNavigation"
        class="n-header__group n-header__second">
        <!-- User -->
        <router-link
          class="n-header__button n-header__item-user user"
          :to="userLink">
          <ui-icon
            class="user__icon"
            glyph="user"
          />

          <span class="user__label">{{ userLabel }}</span>
        </router-link>

        <!-- Notifications -->
        <button
          class="n-header__button n-header__item-notifications notifications"
          ref="notifications"
          @click="onNotifications">
          <div
            class="notifications__count"
            v-if="notificationsCount"
          >{{ notificationsCount }}</div>

          <ui-icon class="notifications__icon" glyph="bell" />
        </button>

        <!-- Locale -->
        <button
          class="n-header__button n-header__item-locale locale"
          ref="locale"
          @click.stop.prevent="onLocales">
          <ui-icon
            class="locale__icon"
            glyph="globe"
          />

          <span class="locale__label">{{ userLang }}</span>
        </button>
      </div>

      <!-- Third -->
      <div
        v-if="!disableNavigation"
        class="n-header__group n-header__third">
        <!-- Burger -->
        <div class="n-header__item">
          <button
            ref="burgercta"
            class="n-header__button n-header__burger-cta"
            @click="showBurger">
            <span class="n-header__button-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <modal-notifications
      :target="$refs.notifications"
      :visible="showNotifications"
      @close="onNotifications"
      @update="onUpdateNotifs"
    />
    
    <modal-locales
      :target="$refs.locale"
      :visible="showLocales"
      @close="onLocalesClose"
      @update="onChangeLang"
    />
  </header>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import { ClickOutside } from '@spices/pepper'
import UiImg from '@/components/ui/img'
import ModalLocales from '../modals/locales.vue'
import ModalNotifications from '@/components/modals/notifications.vue'

export default {
  name: 'NavigationsHeader',

  components: {
    ModalLocales,
    ModalNotifications,
    UiImg,
  },

  directives: {
    ClickOutside
  },

  inject: [
    '$core',
    '$embed',
    '$user',
    '$localStorage',
    '$notification'
  ],

  data() {
    return {
      outside: {
        handler: this.onClickOutside,
        middleware: this.clickOutsideMiddleware
      },

      debounceNotifications: null,
      canNotifications: true,
      showNotifications: false,

      showLocales: false,
      canShowLocales: true,
      keyNotifs: 1,

      bootstrap: SaylStore.bootstrap()
    }
  },

  computed: {
    // Pinia stores Tmp
    burger() {
      return this.bootstrap.burger
    },

    locales() {
      return this.bootstrap.locales
    },

    i18n() {
      return this.bootstrap.i18n
    },
    // End Store

    attrs() {
      return {
        class: this.classes,
      }
    },

    classes() {
      return {
        'n-header': true,
        '-is-active': !this.burger
      }
    },

    disableNavigation() {
      return this.$route.name === 'sayl-front-catalog.b2b'
    },

    embed() {
      return this.$embed.embed || null
    },

    enabled() {
      let meta = this.$route.meta;
      return !meta.hasOwnProperty('header') || (meta.hasOwnProperty('header') && meta.header === true);
    },

    hasNotification() {
      return this.keyNotifs && !this.$basil.isNil(this.$notification.notification)
    },

    langs() {
      return this.locales.map((l) => {
        return {
          label: this.$t(`user-portal.lang_${l.iso.toLowerCase()}`),
          value: l
        }
      });
    },

    logo() {
      // return 'https://dczszawruqwxj.cloudfront.net/infinitycommerce/shop/642405b9eff17599ad0d6de4/64244f4e267e1.png'
      return this.$basil.get(this.embed, 'logo')
    },

    logoHref() {
      return { name: 'sayl-user-portal.home' }
    },

    notificationsCount() {
      let ret = this.$basil.get(this.$notification, 'notifications.length', 0)
      let solo = this.$basil.get(this.$notification, 'notification', null)

      if(solo) {
        ret += 1
      }

      if(ret === 0) {
        return null
      }

      if(ret >= 9) {
        return '9+'
      }

      return this.keyNotifs && ret
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLabel() {
      return this.user.anonymous === true ?
             this.$t('user-portal.burger_actions_login'):
             `${this.user.firstname} ${this.user.lastname}`
    },

    userLink() {
      return { name: 'sayl-user-portal.home' }
    },

    userLang() {
      return this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },
  },

  methods:{
    showBurger() {
      this.bootstrap.burger = true
    },

    clickOutsideMiddleware(event) {
      return true
    },

    async onChangeLang(value) {
      this.showLocales = false
      this.i18n.setLocale(value)
      
      try {
        await this.$core.changeLang({ locale: value.iso })
        this.$bus.$emit('change-lang')
      } catch(e) {
        $console.error(e)
      }
    },

    onClickOutside (event) {
      this.showFavorites = false
      this.showRecent = false
      this.showUser = false
    },

    onLocalesClose() {
      if(this.showLocales === true) {
        this.showLocales = false
        this.canShowLocales = false
        setTimeout(() => this.canShowLocales = true, 200)
      }
    },

    onLocales() {
      this.showLocales = this.canShowLocales ? !this.showLocales : this.showLocales

      if(this.showLocales === true) {
        this.canShowLocales = false
      }

      setTimeout(() => this.canShowLocales = true, 200)
    },

    onNotifications() {
      if(this.canNotifications) {
        this.canNotifications = false
        this.showNotifications = !this.showNotifications

        this.debounceNotifications = setTimeout(() => {
          this.canNotifications = true
        }, 100)
      }
    },

    onUpdateNotifs() {
      this.keyNotifs++
    }
  },

  mounted() {
    if(this.hasNotification) {
      this.onNotifications()
    }
  }
}
</script>
