export default {
  fqn: 'ifm.poap',

  endpoints: {
    collection: {
      get: '/user/poaps?limit=$limit&page=$page',
    },

    location: {
      get: '/poap-location/$id',
      post: 'user/poap/$id/collect'
    },

    multiple: {
      get: '/poap-collection/$token',
      post: '/user/poaps/$token/collect'
    },

    embed: {
      get: '/embed/$id?locale=$locale'
    },
  },

  transport: 'cp'
}