import { isDevelopment } from '@/config'

export default async ({ args, core, logger, store, transports }) => {
  try {
    logger.group('bootstrap.front.core');
  
    transports.getByName = (name) => {
      return transports[name]
    }
  
    let bootstrap = await store.bootstrap().getConfig()
  
    await core.init({ args, bootstrap, store })
    document.title = core.embed.embed.name
  
    if(isDevelopment()) {
      let style = document.createElement('style')
      style.innerHTML = core.embed.embed.customCss
      document.head.append(style)
    }

    return 
  } catch(e) {
    throw e
  } finally {
    logger.groupEnd('bootstrap.front.core')
  }
}
