import { defineStore } from 'pinia'

export const usePoapStore = defineStore('poap', {
  state: () => ({
    // Datas
    intent: null,
    poapLocation: null,
    poapCollection: null,
    poaps: [],
    pagination: {
      limit: 25,
      page: 1,
      pages: 0,
      total: 0,
    },

    // Status
    loading: true,
  }),
})