import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * NFT model
 * 
 * @class
 */
export default class NFTContract extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }
}
