import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Loyalty rules
 *
 * @class 
 */
export default class Rules extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }

  ///////////////////////////////////
  // METHODS
  convertMoneyToPoints(value) {
    let ratio = this.moneyToPoints
    return (value * ratio)
  }

  convertPointsToMoney(value) {
    let ratio = this.pointsToMoney
    return (value * ratio)
  }
}
