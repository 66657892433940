<template>
  <div 
    id="app" 
    :class="classes">
    <!-- Connectivity -->
    <ui-connectivity />

    <!-- Loading -->
    <section
      ref="app-loader"
      class="app-loader"
      v-if="isLoading && !isErrored">
      <div class="app-loader__body">
        <a
          href="https://saylcloud.com/resto"
          class="app-loader__cta"
          target="_blank">
          <ui-img
            class="app-loader__logo"
            :src="logo"
            alt="Sayl Resto Logo"
          />
        </a>

        <div class="app-loader__loader">
          <div class="app-loader__bara"></div>
          <div class="app-loader__barb"></div>
          <div class="app-loader__barc"></div>
          <div class="app-loader__bard"></div>
          <div class="app-loader__bare"></div>
          <div class="app-loader__barf"></div>
        </div>
      </div>
    </section>

    <!-- Loading Error -->
    <error-view
      :errors="bootstrap.errors"
      v-if="isErrored"
    />

    <template v-if="!isLoading && !isErrored">
      <!-- Header -->
      <nav-header />
      <nav-burger />

      <router-view />

      <nav-footer />
      
      <modal-cookies /> 
    </template>
  </div>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import UiConnectivity from '@/components/ui/connectivity'
import UiImg from '@/components/ui/img'

import Config from '@/config'

import ModalCookies from '@/components/modals/cookies.vue'
import NavBurger from '@/components/navigations/burger.vue'
import NavFooter from '@/components/navigations/footer.vue'
import NavHeader from '@/components/navigations/header.vue'

import ErrorView from '@/modules/errors/error'

import 'video.js/dist/video-js.css'

export default {
  name: 'SaylUser',

  components: {
    ErrorView,
    UiImg,
    ModalCookies,
    NavBurger,
    NavFooter,
    NavHeader,
    UiConnectivity,
  },

  inject: [
    '$localStorage',
    '$user',
    '$redirect',
  ],

  data() {
    return {
      key: 1,
      bootstrap: SaylStore.bootstrap()
    }
  },

  computed: {
    classes() {
      return {
        '-is-loading': this.isLoading,
        '-is-active': !this.isLoading,
      }
    },

    isLoading() {
      return [
        this.bootstrap.states.APPLICATION_INIT, 
        this.bootstrap.states.APPLICATION_AUTH, 
        this.bootstrap.states.APPLICATION_MODULES
      ].includes(this.bootstrap.status)
    },

    isErrored() {
      return this.bootstrap.errors && this.bootstrap.errors.length > 0
    },

    isErroredEatin() {
      return this.errorEatin
    },

    logo() {
      return Config.cdn + 'statics/images/logos/resto/color.svg'
    },
  },

  watch: {
    isLoading: {
      async handler() {
        if(this.isLoading === false) {
          if(this.$user.user && this.$user.user.isLogged && this.$redirect.isValid) {
            try {
              await this.$user.propagate({ 
                redirect: this.$redirect.redirect, 
                targetEmbedId: this.$redirect.target 
              })
              window.location.href = this.$redirect.redirect
            } catch(e) {}
          }
        }
      }
    }
  },

  methods: {
    resize() {
      setTimeout(() => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }, 250)
    },
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), { get: (searchParams, prop) => searchParams.get(prop) })
    let email = params.email
    if(email) {
      sessionStorage.setItem('email', email)
    }
  }
}
</script>
