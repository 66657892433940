import VoucherPerk from "./perks/model";

export default {
  amount: {
    format: value => value != null ? Number(value) : null,
    source: 'amount',
    type: Number
  },
  amountType: {
    source: 'amount_type',
    type: String
  },
  applicationMode: {
    source: 'application_mode',
    type: String
  },
  code: {
    source: 'code',
    type: String
  },
  conditions: {
    source: 'conditions',
    type: Array
  },
  created: {
    source: 'created',
    type: Date
  },
  from: {
    source: 'from',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  isTemplate: {
    source: 'is_template',
    type: Boolean
  },
  lastUsageAt: {
    source: 'last_usage_at',
    type: String
  },
  metadata: {
    source: 'metadata',
    format: (value) => {
      return value == null ? null : value
    }
  },
  modified: {
    source: 'modified',
    type: Date
  },
  name: {
    source: 'name',
    type: String
  },
  nbrPerPerson: {
    format: value => value != null ? Number(value) : null,
    source: 'nbr_per_person',
    type: Number
  },
  nbrTotal: {
    format: value => value != null ? Number(value) : null,
    source: 'nbr_total',
    type: Number
  },
  perks: {
    format: (values) => values != null && values.length > 0 ? values.map(v => new VoucherPerk(v)) : null,
    source: 'perks',
    type: Array,
  },
  perkIds: {
    source: 'perk_ids',
    type: Array
  },
  status: {
    source: 'status',
    type: String
  },
  to: {
    source: 'to',
    type: String
  },
  type: {
    source: 'type',
    type: String
  },
  usageCount: {
    source: 'usage_count',
    type: Number
  },
  web3: {
    default: false,
    source: 'web3',
    type: Boolean,
  }
}