import { CurryApi } from '@spices/curry'
import config from './config'

// Models
import History from './history/model'
import Referral from './referrals/model'

import { useReferralStore } from './store'

/**
 * Controller class for the entity Referral
 * @class
 */
export default class ReferralController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger

    this._store = store
    this._store.register('referral', useReferralStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise the referral program
   * 
   * @param {Object} options
   * @param {Object} options.program
   * 
   * @return
   */
  async init({ program }) {
    try {
      this._store.referral().program = new Referral(program)
      return 
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Array} history
   * @readonly
   * @return {Array}
   */
  get history() {
    return this._store.referral().history
  }

  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.referral().loading
  }

  /**
   * @property {Referral} program
   * @readonly
   * @return {Object}
   */
  get program() {
    return this._store.referral().program
  }

  /**
   * @property {Referral} userReferral
   * @readonly
   * @return {Object}
   */
  get userReferral() {
    return this._store.referral().userReferral
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Get the balance for a user
   * @param {Object} payload 
   */
  async getHistory(payload) {
    try {
      this._store.referral().loading = true
      let { data } = await this._api.get({ type: 'collection', payload })

      let pagination = data && data.meta && data.meta.pagination ? data.meta.pagination : {}
      this._store.referral().history = data && data.data ? 
        new History({ items: data.data }) : new History({ items: [] })

      return { history: this._store.referral().history, pagination }
    } catch(e) {
      throw e
    } finally {
      this._store.referral().loading = false
    }
  }

  /**
   * Get the referral linked to the user.
   * 
   * @if the call finds a user referral, everything is fine
   * @elseif the call returns an 404 error, this will inform the client he has no referral link
   */
  async findOne() {
    try {
      this._store.referral().loading = true

      let { data } = await this._api.get({ type: 'entity', payload: {} })
      this._store.referral().userReferral = data
      
      return this._store.referral().userReferral
    } catch(e) {
      if(e.status === 404) {
        this._store.referral().userReferral = null
        return null
      }

      throw e
    } finally {
      this._store.referral().loading = false
    }
  }

  /**
   * Activate the referral code for the logged user
   */
  async activate() {
    try {
      this._store.referral().loading = true

      let { data } = this._api.post({ type: 'entity', payload: {} })
      this._store.referral().userReferral = data

      return this._store.referral().userReferral
    } catch(e) {
      if(e.status === 404) {
        this._store.referral().userReferral = null
        return null
      }

      throw e
    } finally {
      this._store.referral().loading = false
    }
  }
}
