import { defineStore } from 'pinia'

/**
* Application status
*/
import states from '@/bootstrap/states'

export const useBootstrapStore = defineStore('bootstrap', {
  state: () => ({
    fqn: null,
    model: null,

    // Loading
    errors: [],
    status: 0,
    states: states,

    // Navigations
    navigation: null,
    burger: true,

    // i18n
    i18n: null,
    locale: null,
    locales: null,

    // Config (cp bootstrap)
    data: null,
    args: null,

    // confirm modal
    confirm: false,

    // Cookie
    cookies: {
      valid: true,
      callback: null,
      disabled: false,
    },

    routes: []
  }),

  getters: {
    oat: (state) => {
      let d = {
        allergens: [],
        category_navigation: 'top',
        custom_tc_url: null,
        has_comment: { all: true },
        has_email: { all: true },
        has_fl_selection: true,
        has_newsletter: { all: true },
        has_phone: { all: true },
        has_search : false,
        is_catalog_readonly: false,
        is_category_pictures_enabled: true,
        is_challenge_enabled: false,
        is_email_required: { all: true },
        is_newsletter_opt_out: { all: true },
        is_phone_required: { all: true },
        is_product_pictures_enabled: true,
        is_recommendations_enabled: false,
        is_terms_opt_out: { all: true },
        is_tracing_form_enabled: false,
        is_layout_product_inverted: false,
        is_voucher_enabled: true,
        pin : '1234',
        template : 'spa',
      }

      let shop = basil.get(state, 'data.shop.id', null)
      let settings = basil.get(state, 'data.extensions.oat', [])
      let ret = { allergens: [] }

      if(settings && shop !=  null) {
        ret = settings.find(s => s.shop_id === shop)
        if(!basil.isNil(ret)) {
          let allergens = basil.get(ret, 'allergens', [])
          ret.allergens = allergens.sort((a, b) => a.position - b.position)
        }
      }

      return Object.assign(d, ret)
    },

    venly: (state) => {
      let d = {
        allow_meta_transaction: false
      }

      let settings = basil.get(state, 'data.extensions.venly', {})
      return Object.assign(d, settings)
    }
  },

  actions: {
    getConfig() {
      return Promise.resolve(this.data)
    },

    setBurger(value) {
      this.burger = value
    },

    setError(value) {
      this.errors.push(value)
    },

    setI18n(value) {
      this.i18n = value
      this.locale = value.locale
      this.locales = value.locales
    },

    setLocale(value) {
      this.i18n.setLocale(value.locale)
    },

    setNavigation(value) {
      this.navigation = value
    },

    setStatus(value) {
      this.statuts = value
    },
  },
})