import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Loyalty class for a user
 * 
 * @class
 */
export default class ReferralRules extends CurryModel{
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }
}
