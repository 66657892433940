import NFT from '../nfts/model';

export default {
  created: {
    source: 'created',
    type: String
  },
  email: {
    source: 'email',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  lang: {
    source: 'lang',
    type: String
  },
  modified: {
    source: 'modified',
    type: String
  },
  nftId: {
    source: 'nft_id',
    type: String
  },
  nft: {
    source: 'nft',
    type: NFT
  },
  serial: {
    source: 'serial',
    type: String
  },
  status: {
    source: 'status',
    type: String
  },
  tokenId: {
    source: 'token_id',
    type: String
  },
  transactionId: {
    source: 'transaction_id',
    type: String
  },
  userId: {
    source: 'user_id',
    type: String
  }
}
