import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Loyalty settings
 * 
 * @class
 */
export default class Settings extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }
}
