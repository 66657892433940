<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h3 class="layout-modal__title">{{ $t('user-portal.cookies_default_title') }}</h3>
    </template>

    <article
      class="layout-modal__article flow" 
      :key="key">
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :class="{
          'modal-cookies-settings__option': true,
          '--disabled': option.required
        }"
        :hint="option.description ? option.description : null"
        :key="option.key"
        :read-only="option.required"
        :size="$pepper.Size.S"
        v-model="value[option.key]"
        v-for="option in options"
      >{{ option.title }}</forms-checkbox>
    </article>

    <template #footer>
      <actions-button 
        :disabled="loading"
        :size="$pepper.Size.S"
        @click="back"
      >{{ $t('user-portal.cookies_back_all_action') }}</actions-button>

      <div class="group">
        <actions-button 
          :appearance="$pepper.Appearance.DEFAULT"
          :disabled="loading"
          :size="$pepper.Size.S"
          @click="onAcceptSelection"
        >{{ $t('user-portal.cookies_confirm_action') }}</actions-button>

        <actions-button 
          :appearance="$pepper.Appearance.PRIMARY"
          :disabled="loading"
          :size="$pepper.Size.S"
          @click="onAcceptAll"
        >{{ $t('user-portal.cookies_accept_all_action') }}</actions-button>
      </div>
    </template>
  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal.vue'

import CookiesMixin from '@/helpers/cookies'

export default {
  name: 'ModalCookiesSettings',

  components: {
    LayoutModal
  },

  inject: [
    '$embed',
  ],

  mixins: [
    CookiesMixin,
  ],

  props: {
    value: {
      type: Object,
      required: true,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    attrs() {
      return {
        article: true,
        classes: {
          'modal-cookies-settings': true,
        },
        title: this.$t('user-portal.modal_cookies_title'),
        visible: this.visible
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

  },

  methods: {
    back() {
      this.$emit('close')
    },
  },
}
</script>