export default {
  fqn: 'ifm.notifications',

  endpoints: {
    collection: {
      get: '/user/notifications?limit=$limit&page=$page',
    },

    entity: {
      get: '/notification/$token',
      post: '/user/notifications/read',
    },
    
    transport: 'cp'
  },
}
