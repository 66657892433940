export default {
  fqn: 'ifm.loyalty',

  endpoints: {
    collection: {
      get: '/user/balance?limit=$limit&page=$page'
    },

    cards: {
      get: '/user/cards?limit=$limit&page=$page'
    },

    linkCard: {
      post: '/user/card'
    },
    
    unusedCard: {
      post: '/unused-card'
    },

    vouchers: {
      post: '/loyalty/trade'
    },
  },
  
  transport: 'cp'
}
