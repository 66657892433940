import LoyaltyReferralRewardModel from '../rewards/model'

export default {
  active: { source: 'active', type: Boolean, default: false, },
  id: { source: 'id', type: String, },
  linkUtm: { source: 'link_utm', type: Object, default: { source: 'referral', medium: 'url' } },
  loyaltyProgramId: { source: 'loyalty_program_id', type: String, },
  referrerRewards: { source: 'referrer_rewards', type: Array, format: (values) => values ? values.map(v => new LoyaltyReferralRewardModel(v)) : [] },
  referringRewards: { source: 'referring_rewards', type: Array, format: (values) => values ? values.map(v => new LoyaltyReferralRewardModel(v)) : [] },
  
  created: { api: false, source: 'created', type: String, },
  modified: { api: false, source: 'modified', type: String, },
  projectId: { api: false, source: 'project_id', type: String, },
}
