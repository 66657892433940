import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Loyalty class for a user
 * 
 * @class
 */
export default class Loyalty extends CurryModel{
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})

    if(data.page_content == null) {
      this.pageContent = null
    }

    if(data.page_style == null) {
      this.pageStyle = null
    }
  }
}
