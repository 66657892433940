import SaylStore from '@/bootstrap/stores'

export default {
  computed: {
    i18n() {
      return SaylStore.bootstrap().i18n
    },

    currencyCode() {
      return this.$basil.get(this.$i18n.getNumberFormat(this.$i18n.locale), 'currency.currency', 'EUR')
    },
  },

  methods: {
    toCurrency(value, signed = false) {
      value = Number.parseFloat(value).toFixed(2)
      let ret = this.i18n.toCurrency(value)

      if (signed === true) {
        let pre = value < 0 ? '' : '+'
        ret = pre + ret
      }

      return ret
    },

    toRangeCurrency({ min, max, signed = false }) {
      return `${this.toCurrency(min, signed)} - ${this.toCurrency(max, signed)}`
    }
  }
}
