import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * NFT model
 * 
 * @class
 */
export default class NFT extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }

  get assetUrl() {
    return [
      this.imageUrl,
      this.imagePreviewUrl,
      this.imageThumbnailUrl,
    ].find(a => a != null)
  }

  get attributes() {
    let ret = this.attributesRaw || []
    return ret.filter(r => {
      return [
        r.type != 'system', 
        r.name !== 'Token Type ID',
        r.name !== 'Mint Number'
      ].find(v => v === false) == null
    })
  }
}
