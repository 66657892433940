import api from './api/index'
import { basil } from '@spices/basil'
import http from './api/transports/http'
import Error from '@/helpers/error'

export default class DataLayer {
  async bootstrapClientProject({ locale, store }) {
    try {
      let data = await api.bootstrap({ locale })

      // Start the keep alive
      this.keepAlive()

      // Save the config in the store
      store.bootstrap().data = data
      store.bootstrap().cookies.disabled = basil.get(data, 'gdpr.disable_cookies_consent')

      return data
    } catch(error) {
      let e = error

      if(error.message && error.message.includes('404')) {
        e = Error.EMBED_NOTFOUND
      }

      if(error.message && error.message.includes('403')) {
        e = Error.EMBED_SERVERISSUE
      }

      throw e
    }
  }

  async bootstrapLocal({ store }) {
    try {
      let { data } = await http.get('/statics/json/bootstrap.json')
      
      // Save the config in the store
      store.bootstrap().data = data

      return data
    } catch(e) {
      throw e 
    }
  }

  keepAlive() {
    setInterval(() => api.keepAlive(), 1000 * 60 * 5)
  }

  logout() {
    api.logout()
  }

  async reset({ store }) {
    try {
      let data = await api.reset()
      // Save the config in the store
      store.bootstrap().data = data
      return data
    } catch(e) {
      throw e
    }
  }
}
