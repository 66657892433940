let modules = [
  {
    fqn: 'user',
    url: () => require('./user')
  },
  {
    fqn: 'claim',
    url: () => require('./claim')
  },
  {
    fqn: 'stream',
    url: () => require('./stream')
  },
]

export default modules
