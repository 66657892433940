import { basil } from '@spices/basil'

export default class RedirectHandler {
  constructor(data = { redirect: null, targetEmbedId: null }) {
    this._redirect = data.redirect
    this._target = data.targetEmbedId
    
    if(this._redirect != null && this._target != null) {
      sessionStorage.setItem('redirect_login', JSON.stringify({ redirect: this._redirect, target: this._target }))
    } else {
      sessionStorage.removeItem('redirect_login')
    }
  }

  get isValid() {
    return [
      !(basil.isNil(this.redirect) || basil.isEmpty(this.redirect)),
      !(basil.isNil(this.target) || basil.isEmpty(this.target)),
    ].filter(s => s === false).length === 0
  }

  get redirect() {
    let ret = null

    try {
      let s = sessionStorage.getItem('redirect_login')
      if(s) {
        s = JSON.parse(s)
        ret = s.redirect
      } else {
        ret = this._redirect
      }
    } catch(e) {
      ret = this._redirect
    }
    
    return ret
  }

  get target() {
    let ret = null

    try {
      let s = sessionStorage.getItem('redirect_login')
      if(s) {
        s = JSON.parse(s)
        ret = s.target
      } else {
        ret = this._target
      }
    } catch(e) {
      ret = this._target
    }

    return ret
  }

  clear() {
    sessionStorage.removeItem('redirect_login')
  }

  toString() {
    let ret = []

    if(this.redirect != null) {
      ret.push(`redirect=${this.redirect}`)
    }

    if(this.target != null) {
      ret.push(`target_embed_id=${this.target}`)
    }

    return ret.length > 0 ? ret.join('&') : null
  }
}