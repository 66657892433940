export default {
  fqn: 'ifm.nft',

  endpoints: {
    entity: {
      get: '/nft/transfer/$id',
      post: '/user/nft/claim',
    },

    embed: {
      get: '/embed/$id?locale=$locale'
    },
    
    vouchers: {
      get: '/user/nft/vouchers',
    },

    selfClaim: {
      get: '/user/nft/$id/self-claim',
      post: '/user/nft/self-claim',
    },

    transfer: {
      get: '/nft/peer-transfer/$id',
      post: '/user/nft/$address/$serial/transfer',
      delete: '/user/nft/transfer/$id'
    },

    confirmTransfer: {
      post: '/user/nft/transfer'
    },

    signPeerTransfer: {
      post: '/nft/peer-transfer/$token'
    }
  },

  transport: 'cp'
}