import { defineStore } from 'pinia'

export const useNftStore = defineStore('nft', {
  state: () => ({
    // Datas
    intent: null,
    nft: null,
    transfer: null, 
    peerTransfer: null,
    nfts: [],
    vouchers: [],
    triggers: [],

    claimMeta: null,


    // Status
    loading: true,
  }),
})