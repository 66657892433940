import NFTAttribute from './attributes/model'
import NFTContract from './contracts/model'

export default {
  allowUserTransfer: {
    source: 'allow_user_transfer',
    type: Boolean,
  },
  animationUrls: {
    source: 'animationUrls',
    type: Array
  },
  attributesRaw: {
    format: values => values ? values.map(v => new NFTAttribute(v)) : [],
    source: 'attributes',
    type: Array
  },
  backgroundColor: {
    source: 'backgroundColor',
    type: String
  },
  balance: {
    source: 'balance',
    type: Number
  },
  contract: {
    source: 'contract',
    type: NFTContract
  },
  description: {
    source: 'description',
    type: String
  },
  embed: {
    source: 'embed',
    type: Object,
    default: null,
  },
  embedId: {
    source: 'embed_id',
    type: String,
    default: null,
  },
  finalBalance: {
    source: 'finalBalance',
    type: Number
  },
  fungible: {
    source: 'fungible',
    type: Boolean
  },
  id: {
    source: 'id',
    type: String
  },
  imagePreviewUrl: {
    source: 'imagePreviewUrl',
    type: String
  },
  imageThumbnailUrl: {
    source: 'imageThumbnailUrl',
    type: String
  },
  imageUrl: {
    source: 'imageUrl',
    type: String
  },
  name: {
    source: 'name',
    type: String
  },
  transferFees: {
    source: 'transferFees',
    type: Boolean
  },
  url: {
    source: 'url',
    type: String
  }
}