export default {
  fqn: 'ifm.challenges',
  
  endpoints: {
    collection: {
      get: '/challenges',
    },

    entity: {
      get: '/challenge/$id'
    },

    challengeCompletions: {
      get: '/challenge/$id/completions'
    },

    challengesCompletions: {
      get: '/challenges/completions?sort[order]=desc&sort[field]=created&limit=$limit&page=$page'
    },

    settings: {
      get: '/challenges/settings',
    },
  },

  transport: 'cp'
}
