import SaylStore from '@/bootstrap/stores'

const isAuthenticated = (to, from, next) => {
  if(SaylStore.user().user.isLogged) {
    next()
    return
  }

  const params = new Proxy(
    new URLSearchParams(window.location.search), 
    { get: (searchParams, prop) => searchParams.get(prop) }
  )

  let utmSource = params.utm_source || sessionStorage.getItem('utmSource')
  let utmMedium = params.utm_medium || sessionStorage.getItem('utmMedium')

  if(utmSource != null) {
    sessionStorage.setItem('utmSource', utmSource)
  }

  if(utmMedium != null) {
    sessionStorage.setItem('utmMedium', utmMedium)
  }

  if(
    SaylStore.referral().program && 
    SaylStore.referral().program.active &&
    utmSource === SaylStore.referral().program.linkUtm.source &&
    utmMedium === SaylStore.referral().program.linkUtm.medium
  ) {
    next({ name: 'sayl-user-portal.signup' })
    return
  }

  next({ name: 'sayl-user-portal.login' })
  return
}

const isNotAuthenticated = (to, from, next) => {
  if(!SaylStore.user().user.isLogged) {
    next()
    return
  }

  next({ name: 'sayl-user-portal.home' })
}

const isClaimAuthenticated = (to, from, next) => {
  if(SaylStore.user().user.isLogged) {
    next()
    return
  }

  if(to.meta && to.meta.selfClaim) {
    next({ name: 'sayl-user-portal.self-claim.nft.signup', params: to.params })
    return
  }

  if(to.meta && to.meta.peerTransfer) {
    next({ name: 'sayl-user-portal.peer-transfer.nft.signup', params: to.params })
    return
  }

  next({ name: 'sayl-user-portal.claim.nft.signup', params: to.params })
}

const isNotClaimAuthenticated = (to, from, next) => {
  if(!SaylStore.user().user.isLogged) {
    next()
    return
  }

  if(to.meta && to.meta.selfClaim) {
    next({ name: 'sayl-user-portal.self-claim.nft.confirm', params: to.params })
    return
  }

  if(to.meta && to.meta.peerTransfer) {
    next({ name: 'sayl-user-portal.peer-transfer.nft.confirm', params: to.params })
    return
  }

  next({ name: 'sayl-user-portal.claim.nft.confirm', params: to.params })
}

const isPoapClaimAuthenticated = (to, from, next) => {
  if(SaylStore.user().user.isLogged) {
    next()
    return
  }

  next({ name: 'sayl-user-portal.claim.poap.signup', params: to.params })
}

const isNotPoapClaimAuthenticated = (to, from, next) => {
  if(!SaylStore.user().user.isLogged) {
    next()
    return
  }

  next({ name: 'sayl-user-portal.claim.poap.confirm', params: to.params })
}

const isPoapsClaimAuthenticated = (to, from, next) => {
  if(SaylStore.user().user.isLogged) {
    next()
    return
  }

  next({ name: 'sayl-user-portal.claim.poaps.signup', params: to.params })
}

const isNotPoapsClaimAuthenticated = (to, from, next) => {
  if(!SaylStore.user().user.isLogged) {
    next()
    return
  }

  next({ name: 'sayl-user-portal.claim.poaps.confirm', params: to.params })
}

export {
  isAuthenticated,
  isNotAuthenticated,

  isClaimAuthenticated,
  isNotClaimAuthenticated,

  isPoapClaimAuthenticated,
  isNotPoapClaimAuthenticated,

  isPoapsClaimAuthenticated,
  isNotPoapsClaimAuthenticated,
}