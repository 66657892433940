import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Loyalty tier class
 * 
 * @class
 */
export default class LoyaltyTierReward extends CurryModel{
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }

  get isPerk() {
    return this.perkId != null
  }

  get isVoucher() {
    return this.voucherId != null
  }
}
