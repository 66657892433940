export default {
  description: { source: 'description', type: String, },
  id: { source: 'id', type: String, },
  name: { source: 'name', type: String, },
  position: { source: 'position', type: Number, },
  type: { source: 'type', type: String, },


  // Type Perk
  perk: { source: 'perk', type: Object, },
  perkId: { source: 'perk_id', type: String, },


  // Type Voucher
  amount: { source: 'amount', type: Number, default: null },
  validityPeriod: { source: 'validity_period', type: Number, default: null },
  voucherId: { source: 'voucher_id', type: String, default: null },
}
