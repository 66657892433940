export default () => {
  let ret = [
    ///////////////////
    // Videos
    {
      name: 'sayl-user-portal.stream.video',
      path: '/stream/video/:triggerId',
      component: () => import('./videos/index.vue'),
      meta: {
        bodyClass: 'layout-stream view-stream-video',
        scrollTop: true,
        header: true,
        footer: true
      }
    }
  ]

  return ret
}
