import { defineStore } from 'pinia'

export const useReferralStore = defineStore('referral', {
  state: () => ({
    // Datas
    history: null,
    program: null,
    userReferral: null,

    // Status
    loading: true,
  }),
})