import Config from '@/config'
import {basil} from '@spices/basil'

function funnel(action, label, property, value){
  try {
    sayl.injekt.sp.event('funnel', action, label, property, value)
  } catch (error) {}
}

export default async ({ core, eventbus, logger, store }) => {
  try {
    logger.group('bootstrap.injekt')

    eventbus.$on('injekt.sp.product', (productId) => funnel('product', productId))
    eventbus.$on('injekt.sp.category', (categoryId) => { funnel('category', categoryId) })
    eventbus.$on('injekt.sp.cart', () => { funnel('cart') })
    eventbus.$on('injekt.sp.login', () => { funnel('login') })
    eventbus.$on('injekt.sp.registration', () => { funnel('registration') })
    eventbus.$on('injekt.sp.checkout', () => { funnel('checkout') })
    eventbus.$on('injekt.sp.payment', () => { funnel('payment') })
    eventbus.$on('injekt.sp.confirm', () => { funnel('confirm') })

    let bootstrap = await store.bootstrap().getConfig()

    let uid = basil.get(core, '_user._user.id')
    let eid = basil.get(core, '_embed.embed.model.id')

    logger.group('injekt')
    logger.debug('env', Config.injekt.env)
    logger.debug('debug', Config.injekt.debug)
    logger.debug('appId', bootstrap.project_id)
    logger.debug('embedId', eid)
    logger.debug('userId', uid)
    logger.groupEnd('injekt')

    let func = () => {
      try {
        if(sayl.injekt) {
          sayl.injekt.env = Config.injekt.env
          sayl.injekt.sp.appId = bootstrap.project_id
          sayl.injekt.sp.lang = localStorage.getItem('lang')
          sayl.injekt.sp.appName = 'User'
          sayl.injekt.sp.userId = uid
          sayl.injekt.sp._instance.setUserId(uid)
          sayl.injekt.sp.embedId = eid

          funnel('landing', eid)
          logger.info('Funnel:landing:triggered')

          if(!basil.isNil(basil.get(sayl, 'injekt.pusher._channel'))) {
            logger.group('pusher')

            sayl.injekt.pusher._channel.bind('popin', (data) => {
              eventbus.$emit('popin', data)
            })
            logger.debug('popin.registered')

            logger.groupEnd('pusher')
          }
        } else {
          throw new Error('inject error not loaded')
        }
      } catch (error) {
        logger.error('inject error', error)
        logger.error('Funnel:landing:not:triggered')
        setTimeout(func, 1000)
      }
    }

    func()
    return 
  } catch(e) {
    logger.error(e)
    return 
  } finally {
    logger.groupEnd('bootstrap.injekt')
  }
}
