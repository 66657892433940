export default {
  collectibleImage: {
    source: 'collectible_image',
    type: String
  },
  collectionDelay: {
    format: value => {
      try {
        let ret = value != null ? parseInt(value, 10) : null
        return ret
      } catch (e) {
        return value != null ? value : null
      }
    },
    source: 'collection_delay'
  },
  description: {
    source: 'description',
    type: String
  },
  discoveryRadius: {
    format: value => {
      return value != null ? Number(value) : null;
    },
    source: 'discovery_radius'
  },
  embed: {
    source: 'embed',
    type: Object,
    default: null,
  },
  embedId: {
    source: 'embed_id',
    type: String
  },
  geolocation: {
    source: 'geolocation',
    type: Object
  },
  geolocationValidationRadius: {
    format: value => {
      return value != null ? Number(value) : null;
    },
    source: 'geolocation_validation_radius'
  },
  id: {
    source: 'id',
    type: String
  },
  incentive: {
    source: 'incentive',
    type: String
  },
  name: {
    source: 'name',
    type: String
  },
  slug: {
    source: 'slug',
    type: String
  },
  validateGeolocation: {
    default: false,
    source: 'validate_geolocation',
    type: Boolean
  }
}