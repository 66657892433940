<template>
  <popins-popin
    class="view-notifications"
    :key="key"
    position="bottom-left"
    ref="modalNotifications"
    :style="style"
    :target="target"
    :visible="visible"
    v-click-outside="onClose">
    <header class="view-notifications__header">
      <h4 class="view-notifications__title">{{ $t('user-portal.notifications_title') }}</h4>

      <actions-button
        :appearance="$pepper.Appearance.DEFAULT"
        :size="$pepper.Size.S"
        :disabled="!hasNotifications"
        @click="markVisibleAsRead"
        v-if="user.isLogged"
      >{{ $t('user-portal.notifications_mark_visible_read') }}</actions-button>
    </header>

    <section
      class="view-notifications__content"
      v-if="loading">
      <div class="view-notifications__loader">
        <ui-loader />
      </div>
    </section>

    <section
      class="view-notifications__content"
      v-if="!loading">

      <ui-notification
        selected
        :value="notification"
        @close="onClose"
        v-if="hasNotification"
      />

      <ul
        class="view-notifications__list"
        v-if="hasNotifications">
        <ui-notification
          :key="notif.id"
          :value="notif"
          @close="onClose"
          v-for="notif in notifications"
        />
      </ul>

      <data-empty
        :title="$t('user-portal.no_notifications')"
        :description="$t('user-portal.no_notifications_description')"
        v-if="!hasNotifications && !hasNotification"
      />
    </section>
  </popins-popin>
</template>

<script>
import { ClickOutside } from '@spices/pepper'
import UiNotification from '../ui/notification'

export default {
  name: 'ModalNotifications',

  components: {
    UiNotification,
  },

  directives: {
    ClickOutside
  },

  inject: [
    '$user',
    '$notification'
  ],

  props: {
    target: {},

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      debounce: null,
      debounceResize: null,
      canClose: false,
      key: 1,
      screenWidth: 744
    }
  },

  computed: {
    hasNotification() {
      return !this.$basil.isNil(this.notification)
    },

    hasNotifications() {
      return this.notifications.length > 0
    },

    loading() {
      return this.$notification.loading
    },

    notification() {
      return this.$basil.get(this.$notification, 'notification', null)
    },

    notifications() {
      let ret = this.$notification.notifications || []
      return this.key && ret
    },

    style() {
      let ref = this.visible ? this.$refs.modalNotifications : null
      if(ref) {
        if(this.screenWidth > 744) {
          return `left: ${ref.left - 320}px; right: ${ref.right - 320}px`
        } else if(this.screenWidth > 425) {
          return `left: unset; right: 1rem`
        } else {
          return `left: 0px; right: 0px`
        }
      }
      return ``
    },

    user() {
      return this.$user.user
    }
  },

  watch: {
    visible: {
      async handler() {
        if(this.visible) {
          if(this.$user.user.isLogged) {
            try {
              await this.$notification.getNotifications({})
            } catch(e) {
              $console.error(e)
            }
          }

          clearTimeout(this.debounce)
          this.debounce = setTimeout(() => this.canClose = true, 500)

          let ref = this.$refs.modalNotifications
          if(ref) {
            ref.left = ref.left - 320
          }
        }
      }
    }
  },

  methods: {
    async markVisibleAsRead() {
      let ids = this.notifications.map(n => n.id)

      if(this.notification) {
        ids.unshift(this.notification.id)
      }

      try { 
        await this.$notification.read({ ids: ids })
      } catch(e) {
        $console.error(e)
      } finally {
        this.key++
        this.$emit('update')
      }
    },

    onClose() {
      if(this.canClose) {
        this.$emit('close')
        this.canClose = false
      }
    },

    resize() {
      clearTimeout(this.debounceResize)
      this.debounceResize = setTimeout(() => {
        this.screenWidth = document.body.clientWidth
      }, 25)
    },
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
