import { CurryApi } from '@spices/curry'
import config from './config'
import { basil } from '@spices/basil'

// Models
import NFT from '@/core/nfts/nfts/model'
import NFTTranfer from '@/core/nfts/nft-transfers/model'
import Trigger from '@/core/nfts/nfts/triggers/model'
import NftVoucher from './vouchers/model'
import Wallet from './wallets/model'

import { useWalletStore } from './store'
import Voucher from '../users/vouchers/model'
import NFTPeerTranfer from '../nfts/nft-peer-transfers/model'

/**
 * Controller class for the entity Wallet
 * 
 * @class
 */
export default class WalletController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger

    this._store = store
    this._store.register('wallet', useWalletStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise 
   * 
   * @param {Object} options
   */
  async init({ }) {
    try {
      return
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.wallet().loading
  }

  /**
   * @property {Nft} nft
   * @readonly
   * @return {Nft}
   */
  get nft() {
    return this._store.wallet().nft
  }

  /**
   * @property {Number} pendings
   * @readonly
   * @return {Number}
   */
  get pendings() {
    return this._store.wallet().pendings
  }

  /**
   * @property {Array<Nft>} nfts
   * @readonly
   * @return {Array<Nft>}
   */
  get nfts() {
    return this._store.wallet().nfts
  }

  /**
   * @property {Array<NFTTranfer>} transfers
   * @readonly
   * @return {Array<NFTTranfer>}
   */
  get transfers() {
    return this._store.wallet().transfers
  }

  /**
   * @property {Wallet} wallet
   * @readonly
   * @return {Wallet}
   */
  get wallet() {
    return this._store.wallet().wallet
  }

  /////////////////////////////////////////
  ///           METHODS  
  /**
   * @param {Object} options 
   * @param {String} options.id
   */
  async view() {
    try {
      this._store.wallet().loading = true
      
      let { data } = await this._api.get({ type: 'entity', payload: {} }) 
      
      let nfts = data && data.nfts && data.nfts.length > 0 ? data.nfts.map(nft => new NFT(nft)) : []
      let wallet = data && data.wallet ? new Wallet(data.wallet) : null
      
      if(wallet) {
        this._store.wallet().wallet = wallet
        this._store.wallet().nfts = nfts || []
      } else {
        this._store.wallet().wallet = null
        this._store.wallet().nfts = []
      }

      this.getPendings()
      this.getTransfers()

      return this._store.wallet().wallet
    } catch(e) {
      throw e
    } finally {
      setTimeout(() => {
        this._store.wallet().loading = false
      }, 1000)
    }
  }

  async getPendings(args = {}) {
    try {
      let { data } = await this._api.get({ type: 'pendings', payload: {}})

      this._store.wallet().pendings = data && data.length ? data.map(t => new NFTTranfer(t)) : []
      return this._store.wallet().pendings
    } catch(e) {
      this._store.wallet().pendings = []
      throw e
    }
  }

  async getTransfers(args = {}) {
    try {
      this._store.wallet().loadingTransfers = true

      let { data } = await this._api.get({ type: 'transfers', payload: args })
      this._store.wallet().transfers = data && data.length > 0 ? data.map(d => new NFTTranfer(d)) : []
      return this._store.wallet().transfers
    } catch(e) {
      throw e
    } finally {
      this._store.wallet().loadingTransfers = false
    }
  }

  async getVouchersForOneNft(args = { address: null, serial: null }) {
    try {
      let { data } = await this._api.get({ type: 'vouchers', payload: args })
      let vouchers = data && data.length > 0 ? data.map(v => new NftVoucher(v)) : []
      return vouchers
    } catch(e) {
      throw e
    } 
  }

  async getNftData(args = {}) {
    try {
      let { data } = await this._api.get({ type: 'nftData', payload: args })
      let vouchers = data && data.vouchers && data.vouchers.length > 0 ? data.vouchers.map(d => new Voucher(Object.assign(d, { web3: true }))) : []
      let triggers = data && data.triggers && data.triggers.length > 0 ? data.triggers.map(d => new Trigger(d)) : []
      let filtered = {}

      vouchers.forEach(v => {
        let web3id = basil.get(v, 'metadata.web3_voucher_id')
        if(filtered[web3id] == null) {
          filtered[web3id] = []
        }
        filtered[web3id].push(v)
      })

      let nftPeerTransfer = data && data.nft_peer_transfer ? new NFTPeerTranfer(data.nft_peer_transfer) : null
      let contract = data && data.contract ? data.contract : null

      return { contract, vouchers, vouchersSorted: filtered, nftPeerTransfer, triggers }
    } catch(e) {
      throw e
    }
  }

  async resetPin(args = { code, new_pin, new_pin_confirmation }) {
    try {
      await this._api.post({ type: 'pin', payload: { item: args }})
      return
    } catch(e) {
      throw e
    }
  }

  async updatePin(args = { current_pin, new_pin, new_pin_confirmation }) {
    try {
      await this._api.put({ type: 'pin', payload: { item: args }})
      return
    } catch(e) {
      throw e
    }
  }
}
