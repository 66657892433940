import Modules from '@/modules'

export default async ({ logger, router, states, store, Vue }) => {
  logger.group('bootstrap.modules')

  store.bootstrap().status = states.APPLICATION_MODULES

  Modules.forEach(module => {
    let m = module.url()
    let routes = m.default.routes
    routes.forEach(r => router.addRoute(r))
    store.bootstrap().routes = store.bootstrap().routes.concat(routes)

    // Vue.use(m.default, { fqn: m.default.fqn, Vue })
    logger.info(m.default.fqn, '::Installed')
  })

  Vue.use(router)

  logger.groupEnd('bootstrap.modules')
  return
}
