import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    // Datas
    notification: null,
    notifications: [],
    pagination: {},

    // Status
    loading: true,
  }),
})