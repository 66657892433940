import { CurryApi } from '@spices/curry'
import config from './config'

// Models
import { Embed } from '../embeds'
import PoapLocation from './poap-locations/model'

import { usePoapStore } from './store'
import Poap from './poaps/model'
import PoapCollection from './poap-collections/model'

/**
 * Controller class for the entity Poap
 * 
 * @class
 */
export default class PoapController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger

    this._store = store
    this._store.register('poap', usePoapStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise 
   * 
   * @param {Object} options
   */
  async init({ }) {
    try {
      return
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS
  /**
   * @property {String} intent
   * @readonly
   * @return {String}
   */
  get intent() {
    return this._store.poap().intent
  }

  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.poap().loading
  }

  /**
   * @property {Object} pagination
   * @readonly
   * @return {Object}
   */
  get pagination() {
    return this._store.poap().pagination
  }

  /**
   * @property {PoapLocation} poapLocation
   * @readonly
   * @return {PoapLocation}
   */
  get poapLocation() {
    return this._store.poap().poapLocation
  }

  /**
   * @property {PoapCollection} poapCollection
   * @readonly
   * @return {PoapCollection}
   */
  get poapCollection() {
    return this._store.poap().poapCollection
  }

  /**
   * @property {Array<Poap>} poaps
   * @readonly
   * @return {Array<Poap>}
   */
  get poaps() {
    return this._store.poap().poaps
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * Claim a poap 
   * 
   * @param {Object} item 
   * @param {String} item.id
   * @returns 
   */  
  async claimLocation({ id, item }) {
    try {
      this._store.poap().loading = true

      let { data } = await this._api.post({ type: 'location', payload: { id, item }})
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.poap().loading = false
    }
  }
  
  async find(args = { limit: 25, page: 1 }) {
    try {
      this._store.poap().loading = true
      let { data } = await this._api.get({ type: 'collection', payload: args })
      let poaps = data && data.data ? data.data.map(d => new Poap(d)) : []

      let pagination = data && data.meta && data.meta.pagination ? data.meta.pagination : { per_page: 25, current_page: 1, total: 0, total_pages: 1 }
      if(pagination) {
        this._store.poap().pagination = {
          total: pagination.total,
          page: pagination.current_page, 
          pages: pagination.total_pages,
          limit: pagination.per_page,
          hasNext: pagination.current_page < pagination.total_pages
        }
      }

      if(this._store.poap().pagination.page === 1) {
        this._store.poap().poaps = poaps
      } else {
        this._store.poap().poaps.push(...poaps)
      }

      return this._store.poap().poaps
    } catch(e) {
      throw e
    } finally {
      this._store.poap().loading = false
    }
  }

  /**
   * Get an embed base on its ID
   * 
   * @param {Object} args 
   * @param {String} args.id 
   * @returns 
   */
  async getEmbed(args = { id: null }) {
    try {
      let { data } = await this._api.get({ type: 'embed', payload: args })
      return new Embed(data)
    } catch(e) {
      throw e
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.id
   */
  async viewLocation({ id }) {
    try {
      this._store.poap().loading = true
      this._store.poap().poapLocation = null
      
      let { data } = await this._api.get({ type: 'location', payload: { id } })

      let poapLocation = new PoapLocation(data)

      if(poapLocation != null && poapLocation.embedId != null) {
        let embed = await this.getEmbed({ id: poapLocation.embedId })
        poapLocation.embed = embed
      }

      if(poapLocation.embed != null && poapLocation.embed.customCss != null) {
        let style = document.createElement('style')
        style.setAttribute('name', 'claim_style')
        style.setAttribute('type', 'text/css')
        style.textContent = poapLocation.embed.customCss
        document.head.appendChild(style)
      }

      this._store.poap().poapLocation = poapLocation
      return this._store.poap().poapLocation
    } catch(e) {
      throw e
    } finally {
      this._store.poap().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.token
   */
  async viewCollection({ token }) {
    try {
      this._store.poap().loading = true
      this._store.poap().poapCollection = []
      
      let { data } = await this._api.get({ type: 'multiple', payload: { token } })

      let collection = new PoapCollection(data)

      if (collection && Array.isArray(collection.poapLocations)) {
        let poapLocation = collection.poapLocations.find(location => location.embedId != null)
        if (poapLocation) {
          let embed = await this.getEmbed({ id: poapLocation.embedId })
          collection.embed = embed
        }
      }

      if(collection.embed != null && collection.embed.customCss != null) {
        let style = document.createElement('style')
        style.setAttribute('name', 'claim_style')
        style.setAttribute('type', 'text/css')
        style.textContent = poapLocation.embed.customCss
        document.head.appendChild(style)
      }

      this._store.poap().poapCollection = collection
      return this._store.poap().poapCollection
    } catch(e) {
      throw e
    } finally {
      this._store.poap().loading = false
    }
  }

  /**
   * Claim a poaps collection
   * 
   * @param {Object} item 
   * @param {String} item.id
   * @returns 
   */  
  async claimCollection({ token, item }) {
    try {
      this._store.poap().loading = true

      let { data } = await this._api.post({ type: 'multiple', payload: { token, item }})
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.poap().loading = false
    }
  }
}
