export default {
  address: {
    source: 'address',
    type: String
  },
  categories: {
    source: 'categories',
    type: Array
  },
  description: {
    source: 'description',
    type: String
  },
  imageUrl: {
    source: 'imageUrl',
    type: String
  },
  media: {
    source: 'media',
    type: Object
  },
  name: {
    source: 'name',
    type: String
  },
  premium: {
    source: 'premium',
    type: Boolean
  },
  symbol: {
    source: 'symbol',
    type: String
  },
  type: {
    source: 'type',
    type: String
  },
  url: {
    source: 'url',
    type: String
  },
  verified: {
    source: 'verified',
    type: Boolean
  }
}
