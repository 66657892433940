export default {
  fqn: 'ifm.video',

  endpoints: {
    entity: {
      get: '/user/video/$triggerId'
    },

    keepalive: {
      get: '/user/video/$videoId/keepalive'
    }
  },

  transport: 'cp'
}