export default {
  completionsCount: { source: 'completions_count', type: Number, },
  description: { source: 'descr', type: String },
  descriptionMini: { source: 'descr_mini', type: String },
  from: { source: 'from', type: String },
  globalAmountRewards: { source: 'global_amount_rewards', type: Number, },
  id: { source: 'id', type: String },
  images: { source: 'images', type: Array },
  name: { source: 'name', type: String },
  position: { source: 'position', type: Number },
  rewardDescr: { source: 'reward_descr', type: String },
  rewardType: { source: 'reward_type', type: String },
  rewardTypeSettings: { source: 'reward_type_settings', type: Object },
  to: { source: 'to', type: String },
  userAmountRewards: { source: 'user_amount_rewards', type: Number, },
  userCompletionsCount: { source: 'user_completions_count', type: Number },
}
