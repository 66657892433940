import { defineStore } from 'pinia'

export const useEmbedStore = defineStore('embed', {
  state: () => ({
    // Datas
    embed: null,

    // Status
    loading: true,
  }),
})