export default {
  address: {
    source: 'address',
    type: String
  },
  archived: {
    source: 'archived',
    type: Boolean
  },
  description: {
    source: 'description',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  secretType: {
    source: 'secretType',
    type: String
  },
  walletType: {
    source: 'walletType',
    type: String
  }
}