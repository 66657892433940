import VueBodyClass from '../router/body-class'

export default async ({ logger, store, router }) => {
  logger.group('bootstrap.body')

  const bodyClass = new VueBodyClass(store.bootstrap().routes)

  // Initialization
  setTimeout(() => {
    let list = []
    if (document.body.classList.contains('theme-user')) {
      list.push('theme-user')
    }
    if (document.body.classList.contains('-no-scroll')) {
      list.push('-no-scroll')
    }

    bodyClass.guard(router.currentRoute, () => {
      document.body.classList.add(...list)
    })
  }, 500)

  // Route change
  router.beforeEach((to, from, next) => {
    bodyClass.guard(to, () => {
      let list = []
      if (document.body.classList.contains('theme-user')){
        list.push('theme-user')
      }
      if (document.body.classList.contains('-no-scroll')){
        list.push('-no-scroll')
      }

      next()
      document.body.classList.add(...list)
    })
  })

  logger.groupEnd('bootstrap.body')
  return
}
