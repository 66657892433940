import { basil } from '@spices/basil'

import config from './config'

export default class LocalStorageController {
  constructor({ args = {}, logger }) {
    this._args = args;
    this._logger = logger;

    for(let entity in basil.get(config, 'items', {})) {
      Object.defineProperty(this, entity, {
        get: () => {
          let value = localStorage.getItem(this.generateKey(entity))
          try {
            return JSON.parse(value);
          } catch {
            return value;
          }
        },

        set: (value) => {
          if(basil.isNil(value)) {
            return localStorage.removeItem(this.generateKey(entity));
          }

          return localStorage.setItem(this.generateKey(entity), JSON.stringify(value));
        },
      });
    }
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise the user
   * @param {*} param0
   * @returns {Promise}
   */
  async init({ args }) {
    try {
      this._logger.info('local-storage.init')
      this._logger.debug(args)

      this._args = args
      return 
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           METHODS
  generateKey(entity) {
    let scopes = basil.get(config, `items.${entity}.scopes`, []);

    if(!scopes.length) {
      return entity;
    }

    let scopesString = scopes
      .map(scope => {
        return basil.get(this._args, scope, null);
      })
      .filter(val => !basil.isNil(val))
      .join('_');

    if(scopesString === '') {
      return entity;
    }

    return `${entity}_${scopesString}`;
  }
}
