import Address from "../address/model"

export default {
  avatar: { source: 'avatar', type: String },
  contactEmail: { source: 'contact_email', type: String },
  contactPhone: { source: 'contact_phone', type: String },
  email: { source: 'email', type: String },
  firstname: { source: 'firstname', type: String },
  birthdate: { source: 'birthdate', type: String },
  id: { source: 'id', type: String },
  lang: { source: 'lang', type: String },
  lastname: { source: 'lastname', type: String },
  nickname: { source: 'nickname', type: String },
  optIn: { source: 'opt_in', type: Boolean, default: false },
  optInTc: { source: 'opt_in_tc', type: Boolean, default: false, },
  phone: { source: 'phone', type: String },

  // Not API
  addresses: { 
    api: false, 
    source: 'addresses', 
    type: Array,
    format: (values) => {
      if(!values || values.length === 0) {
        return []
      }
      return values.map(v => Address.format(v))
    }
  },
  anonymous: { api: false, source: 'anonymous', type: Boolean },
  balance: { api: false, source: 'balance', type: Object },
  confirmed: { api: false, source: 'confirmed', type: Boolean },
  created: { api: false, source: 'created.date', type: String },
  customerId: { api: false, source: 'customer_id', type: String },
  guest: { api: false, source: 'guest', type: Boolean },
  identities: { api: false, source: 'identities', type: Array },
  loyaltyTierId: { api: false, source: 'loyalty_tier_id', type: String, default: null },
  modified: { api: false, source: 'modified.date', type: String },
  parentId: { api: false, source: 'parent_id', type: String },
  status: { api: false, source: 'status', type: String },

  // To be defined
  walletAddress: { api: false, source: 'wallet_address', default: null, type: String },
  walletIsCustodial: { api: false, source: 'wallet_is_custodial', type: Boolean },
}
