import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * @class 
 */
export default class History extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest})
  }
}
