export default {
  amount: { source: 'amount', type: Number },
  balanceBefore: { source: 'balance_before', type: Number },
  created: { source: 'created', type: Date },
  id: { source: 'id', type: String },
  message: { source: 'message', type: String },
  modified: { source: 'modified', type: Date },
  orderId: { source: 'order_id', type: String },
  voucher: { source: 'voucher', format: (value) => {
    return value == null ? null : value
  }},
}