import { CurryModel } from '@spices/curry'
import manifest from './manifest'

export default class ChallengeSettings extends CurryModel {
  constructor(data = null) {
    super({ manifest, data })

    if(data.page_content == null) {
      this.pageContent = null
    }

    if(data.page_style == null) {
      this.pageStyle = null
    }
  }
}
