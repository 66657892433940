import { CurryModel } from '@spices/curry'
import manifest from './manifest'

export default class ChallengeCompletion extends CurryModel {
  constructor(data = null) {
    super({ manifest, data })
    if(data == null || data.credit == null) {
      this.credit = null
    }

    if(data == null || data.voucher == null) {
      this.voucher = null
    }
  }
}
