import { defineStore } from 'pinia'

export const useLoyaltyStore = defineStore('loyalty', {
  state: () => ({
    // Datas
    cards: [],
    cardsMeta: {},

    balanceHistory: null,
    
    program: null,


    // Status
    loading: true,
  }),
})